@import '../helpers/mixins';
@include font-face('Inter', public/fonts/Inter-Thin, 100, normal, woff2 woff);
@include font-face('Inter', public/fonts/Inter-ExtraLight, 200, italic, woff2 woff);
@include font-face('Inter', public/fonts/Inter-Light, 300, normal, woff2 woff);
@include font-face('Inter', public/fonts/Inter-Regular, 400, italic, woff2 woff);
@include font-face('Inter', public/fonts/Inter-Medium, 500, normal, woff2 woff);
@include font-face('Inter', public/fonts/Inter-SemiBold, 600, italic, woff2 woff);
@include font-face('Inter', public/fonts/Inter-Bold, 700, italic, woff2 woff);
@include font-face('Inter', public/fonts/Inter-ExtraBold, 800, normal, woff2 woff);
@include font-face('Inter', public/fonts/Inter-Black, 900, italic, woff2 woff);
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
$font-web: 'Inter', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$roboto: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$pt-serif: 'PT Serif', serif;
