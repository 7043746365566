/*--- form common ---*/
.ant-btn,
.ant-input,
.ant-picker {
  min-height: 38px;
  @extend .radius-sm;
}
.ant-input,
.ant-picker {
  border: 1px solid theme-colors("secondary", "light", 0.3);
  @include transition(all 0.4s ease-in-out);
  &:hover {
    border-color: theme-colors("background", "blue");
    @include transition(all 0.4s ease-in-out);
  }
  &:focus {
    @include transition(all 0.4s ease-in-out !important);
    @include box-shadow(0 0 0 transparent !important);
  }
}

.contact-btn {
  outline: 0;
  .ant-btn {
    min-height: 38px;
    padding: 7px 10px;
  }
}
.contact-input {
  position: relative;
  margin: 0;
  z-index: 2;
  .ant-input {
    min-height: 48px;
    padding: 0.75rem;
    padding-left: 22px;
  }
  textarea.ant-input {
    min-height: 140px;
  }
  .required-star {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9;
    max-width: 8px;
    max-height: 8px;
    min-width: 8px;
    min-height: 8px;
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: theme-colors("background", "red");
      }
    }
  }
}
/*--- button ---*/
.ant-btn {
  text-transform: capitalize;
  font-size: 1rem;
  color: theme-colors("background", "black");
  @include border-radius(0.25rem);
  @include transition(all 0.8s ease-in-out);
  @extend .flexbox;
  @extend .all-center;
  @include media("<phone") {
    font-size: 14px;
  }
  @include media(">=phone", "<tablet") {
    font-size: 14px;
  }
  &:not(:last-child) {
    margin-right: 1rem;
  }

  span {
    flex: 0 0 auto;
    text-transform: initial;
    &:not(:last-child) {
      margin-right: 8px;
    }
    svg {
      width: 15px;
      fill: theme-colors("background", "white");
    }
  }
  &:empty {
    span {
      display: none;
    }
  }
  &:hover {
    border-color: theme-colors("primary", "base");
    color: theme-colors("primary", "base");
    @include transition(all 0.2s ease-in-out);
    @include box-shadow(0 0 0 transparent !important);
  }
  &:focus {
    @include transition(all 0.4s ease-in-out !important);
    @include box-shadow(0 0 0 transparent !important);
    &:after {
      display: none !important;
    }
  }
  &[disabled] {
    background-color: theme-colors("secondary", "base", 0.3);
    &:hover,
    &:focus,
    &:active {
      background-color: theme-colors("secondary", "base", 0.3);
      @include box-shadow(0 0 0 transparent !important);
    }
  }
  &.ant-btn-primary {
    border: 0;
    color: theme-colors("background", "white");
    background-color: theme-colors("primary", "base");
    &[disabled] {
      background-color: theme-colors("primary", "base", 0.5);
      color: theme-colors("background", "white");
      &:hover,
      &:focus,
      &:active {
        background-color: theme-colors("primary", "base", 0.5);
      }
    }
    &.invite-btn {
      min-width: 100px;
    }
  }
  &.conform-btn {
    min-width: 85px;
    background: #3a889f;
  }
  &.conform-cancel-btn {
    min-width: 85px;
    background: #6ec6df;
    color: theme-colors("background", "white");
    border: none;
    &:hover,
    &:focus,
    &:active {
      color: theme-colors("background", "white");
      border: none;
    }
  }
  &.create-project-btn {
    min-width: 180px;
    max-width: 180px;
    color: theme-colors("background", "white");
    text-transform: none;
    svg {
      width: 13px;
      fill: theme-colors("background", "white");
      margin-top: 2px;
      right: 10px;
      position: absolute;
    }
    .ant-menu-vertical {
      min-height: 200px;
    }
  }
  &.ant-btn-dashed {
    border-style: dashed;
  }
  &.ant-btn-link {
    border: 0;
    color: theme-colors("primary", "base");
  }
  &.ant-btn-text {
    border: 0px;
    color: theme-colors("background", "black", 0.6);
  }
  &.ant-btn-danger {
    background-color: theme-colors("background", "red");
    border-color: theme-colors("background", "red");
    color: theme-colors("background", "white") !important;
    &:hover {
      background-color: theme-colors("background", "red")-hov;
      border-color: theme-colors("background", "red")-hov;
    }
  }
  .anticon {
    margin: 0 !important;
  }
  &.next-btn {
    @extend .flexbox;
    @extend .all-center;
    padding: 0px 10px;
    min-height: 34px;
    span {
      flex: 0 0 auto;
      &:first-child {
        margin-left: 10px;
      }
      svg {
        fill: theme-colors("background", "black", 0.6);
      }
    }
  }
  &.back-btn {
    @extend .flexbox;
    @extend .all-center;
    padding: 0px 10px;
    min-height: 34px;
    span {
      flex: 0 0 auto;
      &:first-child {
        margin-right: 10px;
      }
      svg {
        fill: theme-colors("background", "black", 0.6);
      }
    }
  }
  &.prv-btn {
    @extend .flexbox;
    @extend .all-center;
    padding: 0px 10px;
    margin-left: 5px;
    min-height: 34px;
    span {
      flex: 0 0 auto;
      &:first-child {
        margin-right: 10px;
      }
      svg {
        fill: theme-colors("background", "black", 0.6);
      }
    }
  }
  &.file-btn {
    min-height: 30px;
  }
  &.survey-btn {
    border: 2px solid theme-colors("background", "white") !important;
    min-height: 52px;
    padding: 0 40px;
    color: theme-colors("background", "black") !important;
    width: auto !important;
    font-size: 16px !important;
    background-color: theme-colors("background", "white", 0.5) !important;
    @include border-radius(5px);
    @extend .flexbox;
    @extend .all-center;
    span {
      flex: 0 0 auto;
      font-size: 16px !important;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: theme-colors("background", "white", 0.9) !important;
      @include box-shadow(3px 3px 7px rgba(0, 0, 0, 0.1) !important);
    }
    &[disabled] {
      background-color: theme-colors("background", "white", 0.5) !important;
      &:hover,
      &:focus,
      &:active {
        background-color: theme-colors("background", "white", 0.5) !important;
        @include box-shadow(0 0 0 transparent !important);
      }
    }
  }
  &.loader-button {
    outline: 0;
  }
  &.filter-btn {
    @extend .small-text;
    border: 0;
    padding: 0;
    @include box-shadow(0 0 0 transparent !important);
    @include media("<=phone") {
      padding: 8px 0px;
    }
  }
  &.filter-button {
    &:disabled {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }
  &.popover-tree {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent !important;

    @include box-shadow(0 0 0 transparent !important);
    &:hover,
    &:focus {
      @include transition(all 0.2s ease-in-out);
      color: theme-colors("background", "orange");
      background-color: transparent !important;
      &[disabled] {
        border: 0;
        color: theme-colors("background", "black", 0.2);
        &:hover,
        &:focus,
        &:active {
          color: theme-colors("background", "black", 0.2);
        }
      }

      span {
        @include transition(all 0.2s ease-in-out);
        &.down-arrow {
          border: 0;
          path {
            //fill: theme-colors('background', 'orange');
            @include transition(all 0.2s ease-in-out);
          }
        }
      }
    }
    span {
      flex: 0 0 auto;
      &:first-child {
        font-size: 14px;
      }
      &.down-arrow {
        flex: 0 0 auto;
        padding: 2px 0 0 0;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        @extend .flexbox;
        @extend .all-center;
        svg {
          width: 70%;
          height: 70%;
          path {
            fill: #bcbcbc;
          }
        }
      }
    }
  }
  &.custom-question-button {
    min-height: 33px;
    font-size: 14px;
  }
  &.export-button {
    background-color: theme-colors("background", "teal");
    color: white;
    height: 40px;
    font-size: 16px;
  }
  &.ant-btn-link {
    border: 0;
    &:hover {
      border: 0;
      background-color: transparent;
      color: theme-colors("secondary", "base");
    }
  }
  &.header-btn {
    font-size: 1rem;
    border: 0;
    color: theme-colors("background", "white");
    font-weight: 400;
    @extend .btn-gradient;
    @extend .flexbox;
    @extend .all-center;
    @include media("<phone") {
      font-size: 13px;
    }
    @include media(">=phone", "<tablet") {
      font-size: 13px;
    }
    span {
      flex: 0 0 auto;
      &:last-child {
        margin-left: 0px;
      }
    }
    svg {
      margin-right: 5px;
    }
  }
  &.switch-btn,
  &.view-btn,
  &.table-btn {
    display: inline-block;
    max-height: 30px;
    min-height: 30px;
    color: theme-colors("secondary", "light");
    font-weight: 400;
    line-height: 22px;

    &[disabled] {
      border: 0;
      background-color: theme-colors("foreground", "light") !important;
      color: theme-colors("secondary", "light", 0.5);
      &:hover,
      &:focus,
      &:active {
        background-color: theme-colors("foreground", "light") !important;
        color: theme-colors("secondary", "light", 0.5) !important;
      }
    }

    &:hover {
      color: theme-colors("secondary", "light");
      background-color: theme-colors("secondary", "light", 0.2);
    }
  }
  &.switch-btn {
    border: 0;
    background-color: theme-colors("secondary", "light", 0.1);
  }
  &.view-btn {
    background-color: theme-colors("background", "white");
  }
  &.preview,
  &.save,
  &.publish {
    margin: 0;
    svg {
      margin-right: 10px;
      font-size: 14px;
    }
  }
  &.icon {
    padding: 0;
    border: 0;
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px;
    background-color: transparent;
    @extend .flexbox;
    @extend .all-center;
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: #8a8686;
      }
    }
    &:hover {
      background-color: transparent;
    }
  }
  &.btn-ash {
    @include border-radius(5);
    color: theme-colors("background", "white") !important;
    border: 0px;
    height: 36px;
    min-width: 36px;
    background-size: 200%;
    background-color: theme-colors("background", "ash");
    border-color: theme-colors("background", "ash");
    border-image: linear-gradient(
      to right,
      theme-colors("background", "ash"),
      theme-colors("background", "ash-light")
    ) !important;
    background-image: linear-gradient(
      to right,
      theme-colors("background", "ash"),
      theme-colors("background", "ash-light")
    ) !important;
    @include transition(all 0.8s ease-in-out);
    &[disabled] {
      background-color: theme-colors("background", "ash", 0.5) !important;
      border-image: linear-gradient(
        to right,
        theme-colors("background", "ash", 0.5),
        theme-colors("background", "ash-light", 0.5)
      ) !important;
      background-image: linear-gradient(
        to right,
        theme-colors("background", "ash", 0.7),
        theme-colors("background", "ash-light", 0.5)
      ) !important;
      color: theme-colors("background", "white");
      &:focus,
      &:active,
      &:hover,
      &:visited,
      &:focus-within {
        color: theme-colors("background", "white", 0.5);
      }
    }
  }
}
/*--- button group --- */
.btn-group {
  flex: 0 0 auto;
  margin: 0;
  @extend .flexbox;
  @extend .item-center;
  .ant-btn {
    flex: 0 0 auto;
    border-left: 0;
    border-right: 0;
    &:first-child {
      @include border-radius(4px 0 0 4px);
      border-left: 1px solid #d9d9d9;
    }

    &:last-child {
      @include border-radius(0 4px 4px 0);
      border-right: 1px solid #d9d9d9;
    }

    &.active {
      color: theme-colors("background", "white");
      background-color: rgb(44, 135, 238);
    }
  }
}
/*--- ant input --- */
.ant-input {
  @include transition(all 0.4s ease-in-out);
  &:focus {
    @include transition(all 0.4s ease-in-out);
  }
  &.demographics-input {
    border: none;
    background: theme-colors("background", "black", 0.04);
    min-width: 500px;
    max-width: 500px;
    margin-bottom: 8px;
    @include media("<phone") {
      min-width: 100%;
      max-width: 100%;
    }
    @include media(">=phone", "<tablet") {
      min-width: 100%;
      max-width: 100%;
    }
  }
  &.choices-input {
    border: none;
    background: theme-colors("background", "black", 0.04);
  }
  &.seach-input {
    border: 0px;
    height: 36px;
    background-color: #eeeeee;
    padding-left: 40px;
    border: solid 1px theme-colors("background", "black", 0.01);
    color: theme-colors("background", "black");
    @include border-radius(0.313rem);
    @include transition(all 0.4s ease-in-out);
    @include placeholder {
      color: theme-colors("background", "black", 0.5);
     // font-size: 22px;
    }
    &:focus {
      background-color: theme-colors("background", "white", 0.5);
      border: solid 1px theme-colors("background", "black", 0.1);
      @include transition(all 0.4s ease-in-out);
    }
  }
  &.editsurvey-input {
    border: 0px;
    height: 36px !important;
    background-color: #fff;
    padding-left: 0px;
    border:none;
    font-size: 16px;
   // width: 300px;
    font-weight: 600;
    color: theme-colors("background", "black");
    @include border-radius(0.313rem);
    @include transition(all 0.4s ease-in-out);
    @include placeholder {
      color: theme-colors("background", "black", 0.5);
    }
    &__icon{
      width: 20px;
      margin-left: 1rem;
    }
    &:focus {
      background-color: none;
      border:none;
      @include transition(all 0.4s ease-in-out);
    }
  }
  &.file-link {
    border: 0;
    min-height: 30px;
  }
}
/*--- ant input number--- */
.ant-input-number {
  border: 1px solid theme-colors("secondary", "light", 0.3);
  border-radius: 0;
  width: 65px;
  height: 30px;
}
/*--- Select overide--- */
.ant-select {
  flex: 1 1 auto;
  min-height: 38px;
  position: relative;
  @extend .flex-column;

  .ant-select-selector {
    min-height: 36px;
    padding: 0px 1rem;
    background-color: transparent;
    border-bottom: solid 1px theme-colors("background", "black", 0.1);
    @extend .flexbox;
    @extend .all-center;
    @include border-radius(0.313rem);
    .ant-select-selection-search {
      flex: 0 0 auto;
      left: 2px;
      padding: 0 0.5rem;
    }
  }
  .ant-select-selector {
    .ant-select-selection-item {
      @extend .flexbox;
      @include media(">=phone", "<tablet") {
        font-size: 15px;
        // width: 220px;
        overflow: hidden;
        position: relative;
        //display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @include media("<phone") {
        font-size: 13px;
        // width: 220px;
        overflow: hidden;
        position: relative;
        // display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      svg {
        min-width: 10px;
        max-width: 10px;
        margin-top: 0px;
      }
    }
  }
  .ant-select-arrow {
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    top: 50%;
    padding: 0;
    margin: 0;
    @extend .flexbox;
    @extend .all-center;
    @include transform(translate(0, -50%));
    .anticon-down,
    .anticon-search {
      flex: 0 0 auto;
      font-size: 0.86rem;
      color: theme-colors("secondary", "light", 0.5);
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      margin: 0;
      @extend .flexbox;
      @extend .all-center;
      &.anticon {
        margin: 0;
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: theme-colors("secondary", "light", 0.5);
          }
        }
      }
    }
  }
  &:not(.ant-select-customize-input) {
    background-color: transparent;
    .ant-select-selector {
      background-color: transparent;
      border: 1px solid theme-colors("secondary", "light", 0.3);
      @include border-radius(5px);
    }
  }
  &.ant-select-focused,
  &.ant-select-focused:not(.ant-select-disabled),
  &:not(.ant-select-customize-input) {
    outline: 0;
    @include transition(all 0.4s ease-in-out !important);
    @include box-shadow(0 0 0 transparent !important);
    .ant-select-selector {
      @include transition(all 0.4s ease-in-out !important);
      @include box-shadow(0 0 0 transparent !important);
    }
  }
  &.trendnew-select {
    margin: 0;
  
    background: theme-colors("background", "white");
  .ant-select-selector{
    padding: 1rem 1rem !important;
  }

  }
  &.editsurveytext-select {
    margin: 0;
    margin-top: 1rem                    ;
    background: theme-colors("background", "white");
    &__box{
      @extend .flexbox;
      svg{
        min-width: 20px; 
        max-width: 20px; 
      margin-right: .7rem;
    }
    }
   

    .ant-select-selector {
      min-height: 40px;
      padding-left: 22px;
      padding-right: 22px !important;
      @include box-shadow(0 2px 4px theme-colors("background", "black", 0.1));
      .ant-select-item-option-content{
        @extend .flexbox;
        svg{
          min-width: 20px; 
          max-width: 20px; 
          margin-right: .7rem;
          fill: #ed7d31;
      }
      }
      .ant-select-selection-item {
       font-size: 12px !important;
        overflow: hidden;
        position: relative;
        @extend .flexbox;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: .2rem !important;
        @include media(">=1368px") {
          font-size: 14px !important;
      }
        svg{
          min-width: 20px; 
          max-width: 20px; 
          margin-right: .5rem;
          fill: #ed7d31;
        }
       
      }
   
      .ant-select-selection-search {
        padding: 0 18px;
        @extend .flexbox;
        @extend .item-center;
      }

    }
    .ant-select-item{
      @extend .flexbox;
    }
    .ant-select-item-option-content{
      @extend .flexbox;
    }
    .ant-select-arrow {
      right: 40px;
      .anticon {
        font-size: 0.86rem;
        color: theme-colors("secondary", "light", 0.5);
        width: 21px;
        margin-left: 30px;
      }
    }
  }



  &.heatmapconfiguration-select {
    margin: 0;
    background: theme-colors("background", "white");

    .ant-select-selector {
      min-height: 38px;
      padding-left: 22px;
      padding-right: 22px !important;
      @include box-shadow(0 2px 4px theme-colors("background", "black", 0.1));
     
      .ant-select-selection-search {
        padding: 0 18px;
        @extend .flexbox;
        @extend .item-center;
      }
    }
   
  }



  &.topic-select {
    margin: 0;
    background: theme-colors("background", "white");

    .ant-select-selector {
      min-height: 60px;
      padding-left: 22px;
      padding-right: 22px !important;
      @include box-shadow(0 2px 4px theme-colors("background", "black", 0.1));
      .ant-select-selection-item {
        @extend .heading-three;
        overflow: hidden;
        position: relative;
        display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .ant-select-selection-search {
        padding: 0 18px;
        @extend .flexbox;
        @extend .item-center;
      }
    }
    .ant-select-arrow {
      right: 30px;
      .anticon {
        font-size: 0.86rem;
        color: theme-colors("secondary", "light", 0.5);
        width: 21px;
        margin-left: 30px;
      }
    }
  }
  &.trend-select {
    margin: 10px 0px 10px 0px;
    background:  theme-colors("background", "black", .05) !important;
border-radius: 10px;
padding-right: 10px !important;
border: none !important;
    .ant-select-selector {
      min-height: 53px;
      padding-left: 22px;
      padding-right: 22px !important;
      border: none !important;
      @include box-shadow(0 2px 4px theme-colors("background", "black", 0.1));
      .ant-select-selection-item {
        @extend .heading-three;
        overflow: hidden;
        position: relative;
        display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .ant-select-selection-search {
        padding: 0 18px;
        @extend .flexbox;
        @extend .item-center;
      }
    }
    .ant-select-arrow {
      right: 15px;
      .anticon {
        font-size: 0.86rem;
        color: theme-colors("secondary", "light", 0.5);
        width: 21px;
        margin-left: 30px;
      }
    }
  }
  &.demographics-select {
    margin-top: 10px;
    background: theme-colors("background", "black", 0.04);
    @extend .radius-sm;

    .ant-select-selector {
      border: none;
      .ant-select-selection-item {
        @extend .running-text;
        color: theme-colors("background", "black", 0.3);
      }
      .ant-select-selection-search {
        padding: 0px;
        @extend .flexbox;
        @extend .item-center;
      }
    }
    .ant-select-arrow {
      .anticon {
        font-size: 0.86rem;
        color: theme-colors("secondary", "light", 0.5);
        width: 21px;
      }
    }
  }
  &.confirm-select {
    margin-top: 10px;
    min-width: 300px;
    max-width: 300px;
    border: 1px solid theme-colors("secondary", "light", 0.3);
    @extend .radius-sm;
    @include media("<phone") {
      min-width: 100%;
      max-width: 100%;
    }
    @include media(">=phone", "<tablet") {
      min-width: 100%;
      max-width: 100%;
    }
    .ant-select-selector {
      border: none;
      .ant-select-selection-item {
        padding-top: 3px;
        color: theme-colors("text", "primary");
        @extend .running-text;
      }
      .ant-select-selection-search {
        padding: 0 10px;
        @extend .flexbox;
        @extend .item-center;
      }
    }
    .ant-select-arrow {
      .anticon {
        font-size: 0.86rem;
        color: theme-colors("secondary", "light", 0.5);
        width: 21px;
      }
    }
  }
  &.topics-category {
    min-width: 175px;
    max-width: 175px;
    margin-bottom: 1.4rem;
    @extend .flexbox;
    @extend .item-center;
    @extend .justify-between;

    .ant-select-item {
      @extend .flexbox;
      @extend .item-center;
      @extend .justify-between;
    }
    .ant-select-selector {
      .ant-select-selection-item {
        @extend .flexbox;
        svg {
          min-width: 10px;
          max-width: 10px;
          margin-top: 0px;
        }
      }
    }
  }


  &.setup-select {
    min-width: 420px;
    max-width: 420px;
    margin-bottom: 0.4rem;
    @extend .flexbox;
    @extend .item-center;
    @extend .justify-between;
    @include media(">=phone", "<tablet") {
      min-width: 100%;
      max-width: 100%;
    }
    @include media("<phone") {
      min-width: 100%;
      max-width: 100%;
    }
    .ant-select-item {
      @extend .flexbox;
      @extend .item-center;
      @extend .justify-between;
    }
    .ant-select-selector {
      .ant-select-selection-item {
        @extend .flexbox;
        @include media(">=phone", "<tablet") {
          font-size: 15px;
          // width: 220px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        @include media("<phone") {
          font-size: 13px;
          // width: 220px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          min-width: 10px;
          max-width: 10px;
          margin-top: 0px;
        }
      }
    }
  }
  &.participants-select {
    min-width: 420px;
    max-width: 420px;
    margin-bottom: 1.2rem;
    @extend .flexbox;
    @extend .item-center;
    @extend .justify-between;
    @include media(">=phone", "<tablet") {
      min-width: 100%;
      max-width: 100%;
    }
    @include media("<phone") {
      min-width: 100%;
      max-width: 100%;
    }
    .ant-select-item {
      @extend .flexbox;
      @extend .item-center;
      @extend .justify-between;
    }
    .ant-select-selector {
      min-height: 62px;
      max-height: 62px;
      .ant-select-selection-search {
        margin-top: 0.6rem;
      }
      .ant-select-selection-item {
        @extend .flexbox;
        svg {
          min-width: 10px;
          max-width: 10px;
          margin-top: 0px;
        }
      }
    }
  }
  &.heading-select {
    margin: 0;
    flex: none !important;
    // min-width: 300px;
    @include media(">=phone", "<tablet") {
      //min-width: auto;
    }
    @include media("<phone") {
      // min-width: auto;
    }

    .ant-select-selector {
      padding: 0;
      border: 0;
      @extend .heading-one;
      @include border-radius(0);
      // min-width: 300px;

      @include media(">=phone", "<tablet") {
        // min-width: 100%;
      }
      @include media("<phone") {
        // min-width: 100%;
      }

      .ant-select-selection-search-input {
        z-index: 99;
        font-size: 24px;
        @include media(">=phone", "<tablet") {
          font-size: 16px;
        }
        @include media("<phone") {
          font-size: 16px;
        }
      }
      .ant-select-selection-item {
        @extend .heading-one;
        padding-right: 35px;
        @extend .flexbox;
        @extend .item-center;
        @extend .justify-start;
        @include media(">=phone", "<tablet") {
          font-size: 15px;
          // width: auto;
          //width: auto;
          width: 270px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        @include media("<phone") {
          font-size: 13px;
          width: 270px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .ant-select-selection-item-remove {
          max-height: 10px;
        }
      }
      .ant-select-selection-search {
        left: 0;
        padding: 0;
      }
    }
    .ant-select-arrow {
      margin: 0;
      @include media("<=phone") {
        position: absolute;
        right: 0px;
        z-index: 999;
      }
      @include media(">=phone", "<tablet") {
        position: absolute;
        right: 0px;
        z-index: 999;
      }
      .anticon {
        font-size: 1rem;
      }
    }
  }
  &.ant-select-multiple {
    margin: 0;
    .ant-select-selector {
      padding: 0px 8px;
    }
    .ant-select-selection-item {
      @extend .flexbox;
      @extend .item-center;
      .ant-select-selection-item-content {
        font-size: 12px;
      }
      .ant-select-selection-item-remove {
        max-height: 14px;
        @extend .flexbox;
        @extend .all-center;
      }
    }
  }
  &.project-select {
    max-width: 350px;
  }

  &.dimension-select {
    .ant-select-selector {
      padding-left: 14px;
      padding-right: 22px !important;
      overflow: hidden;
      @include box-shadow(0 2px 4px theme-colors("background", "black", 0.1));
      .ant-select-selection-item {
        @extend .flexbox;
      }
      .ant-select-selection-overflow {
        position: absolute;
        left: 8px;
      }
      .ant-select-selection-search {
        padding: 0px;
        left: 0px;
        margin-inline-start: 0px;
        flex: 0 0 auto;
        @extend .flexbox;
        @extend .item-center;
      }
    }
  }
  &.select-box {
    width: 150px;
    margin-left: 10px;
    .ant-select-selector {
      @include border-radius(0);
      border: none;
      border-bottom: solid 1px theme-colors("background", "black", 0.1);
      margin-left: 10px;
      min-width: 100px;
    }
    .ant-select-selection-item {
      margin-bottom: -5px;
    }
  }
  &.storyboard-select {
    .ant-select-selector {
      padding-left: 14px;
      padding-right: 22px !important;
      overflow: hidden;
      @include box-shadow(0 2px 4px theme-colors("background", "black", 0.1));
      .ant-select-selection-item {
        @extend .flexbox;
        max-width: 120px;
      }
      .ant-select-selection-overflow {
        position: absolute;
        left: 8px;
      }
      .ant-select-selection-search {
        padding: 0px;
        left: 0px;
        margin-inline-start: 0px;
        flex: 0 0 auto;
        @extend .flexbox;
        @extend .item-center;
      }
    }
  }
}
/*--- Tab overide--- */
.ant-tabs {
  font-size: 16px;
  font-weight: 500;
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-nav-wrap {
      @extend .flex-column;
      .ant-tabs-nav-list {
        @extend .flexbox;
        @extend .item-center;
        .ant-tabs-tab {
          flex: 0 0 auto;
          @include media("<=phone") {
            margin-right: 1rem;
          }
          @include media(">=phone", "<tablet") {
            margin-right: 1rem;
          }
          .ant-tabs-tab-btn {
            font-size: 16px !important;
            font-weight: 500;
            @include media("<phone") {
              font-size: 14px !important;
            }
            @include media(">=phone", "<tablet") {
              font-size: 14px !important;
            }

            &:hover,
            &:focus {
              color: theme-colors("accent", "primary");
            }
          }
          &.ant-tabs-tab-active {
            @extend .flex-column;
            .ant-tabs-tab-btn {
              color: theme-colors("accent", "primary");
              font-weight: 700;
            }
          }
          &:hover {
            color: #29363d;
          }
        }
        .ant-tabs-ink-bar {
          margin-left: 0px;
          background-color: theme-colors("accent", "primary");
        }
      }
    }
  }
}
/*--- Table overide--- */
.ant-table-wrapper {
  @extend .flex-column;
  @include media(">=phone", "<tablet") {
    overflow-x: auto;
  }
  @include media("<phone") {
    overflow-x: auto;
  }
  .ant-table-container {
    @extend .flex-column;
    &:before,
    &:after {
      display: none;
    }
    .ant-table-thead {
      margin: 0;
      color: #5c6970 !important;
      font-size: 13px !important;
      font-weight: 500;
      span {
        font-weight: 500;
        font-size: 13px !important;
      }
      tr {
        background-color: theme-colors("background", "white");
        .ant-table-cell {
          color: #5c6970;
          font-weight: 500;
          font-size: 13px !important;
          background-color: transparent;
          padding: 0.5rem 1rem;
          vertical-align: middle;
          border-color: #dbdbdb;

          @include transition(all 0.8s ease-in-out);
        }
        .ant-table-column-sorters {
          padding: 0px;
        }
      }
    }
    .ant-table-tbody {
      margin: 0;
      .ant-table-cell {
        border-color: #ececec;
      }
    }
    .ant-table-thead,
    .ant-table-tbody {
      margin: 0;
      .ant-table-cell {
        @extend .running-text;
        color: #29363d;
        padding: 0.5rem 1rem;
        vertical-align: middle;
        @include transition(all 0.8s ease-in-out);
        &.ant-table-column-sort {
          background-color: transparent !important;
        }
      }
      .ant-table-row {
        background-color: theme-colors("background", "white");
        &:hover {
          @include transition(all 0.8s ease-in-out);
          background-color: theme-colors("background", "white");
        }
      }
      &:hover {
        @include transition(all 0.8s ease-in-out);
        background-color: theme-colors("background", "white");
        .ant-table-cell {
          background-color: theme-colors("background", "white");
          @include transition(all 0.8s ease-in-out);
        }
      }
      .ant-table-column-sorters {
        padding: 0px;
        svg {
          &:hover,
          &:active,
          &:focus {
            color: #4799eb;
          }
        }
      }
    }
  }
  &.row-loader {
    margin: 0;
    .ant-table-column-sorters {
      padding: 0px !important;
    }
    .ant-table-tbody {
      outline: 0;
      .ant-table-cell {
        margin: 0;
        &:first-child {
          padding-left: 3rem;
          position: relative;
          .ant-spin {
            max-width: 30px;
            max-height: 30px;
            position: absolute;
            left: 1rem;
            top: 50%;
            @include transform(translate(0, -50%));
            .ant-spin-dot {
              font-size: 16px;
              .ant-spin-dot-item {
                width: 8px;
                height: 8px;
              }
            }
          }
        }
      }
    }
  }
  &.side-linetbl {
    margin: 0;
    .ant-table-thead {
      margin: 0;
      color: #5c6970 !important;
      font-size: 13px !important;
      font-weight: 500;
      span {
        font-weight: 500;
        font-size: 13px !important;
      }
      tr {
        background-color: theme-colors("background", "white");
        .ant-table-cell {
          color: #5c6970;
          font-size: 13px !important;
          font-weight: 500;

          &:first-child {
            padding: 0.5rem 0;
          }
          &:last-child {
            padding: 0.5rem 0;
            text-align: right;
          }
        }
        .ant-table-column-sorters {
          padding: 0px;
        }
      }
    }

    .ant-table-tbody {
      outline: 0;
      .ant-table-cell {
        margin: 0;
        b {
          @extend .running-text;
          color: #29363d;
        }

        &:first-child {
          padding: 0.5rem 0;
        }
        &:last-child {
          padding: 0.5rem 0;
          text-align: right;
        }
      }
    }
  }
  &.vertical-top {
    margin: 0;
    .ant-table-tbody {
      outline: 0;
      .ant-table-cell {
        vertical-align: top;
      }
    }
  }
  &.vertical-bottom {
    margin: 0;
    .ant-table-tbody {
      outline: 0;
      .ant-table-cell {
        vertical-align: baseline;
      }
    }
  }
  &.no-space {
    margin: 0;
    .ant-table-thead {
      span {
      }
      .ant-table-cell {
        margin: 0;
        &:first-child,
        &:last-child {
          padding: 0.5rem 0;
        }
      }
    }

    .ant-table-tbody {
      outline: 0;
      .ant-table-cell {
        margin: 0;
        text-align: left !important;
        &:first-child,
        &:last-child {
          padding: 0.5rem 0;
        }
      }
    }
    .table-arrows {
      @extend .flexbox;
      @extend .item-center;

      svg {
        min-width: 10px;
        max-width: 10px;
        min-height: 10px;
        margin-right: 5px;
      }
    }
    .ant-table-content {
      margin: 0;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: theme-colors('foreground', 'base', 0.3);
        @include border-radius(50px);
      }
    }
  }
  &.survey-table {
    margin: 0;
    .ant-table-thead {
      // border: none;
      .ant-table-cell {
        margin: 0;
        border-bottom: 1px solid theme-colors("background", "black", 0.04) !important;

        &:first-child {
          padding: 0.5rem 0;
          padding-left: 1rem;
        }
        &:last-child {
          padding: 0.5rem 0;
          padding-right: 1rem;
        }
      }
    }

    .ant-table-tbody {
      outline: 0;
      @extend .radius-lg;
      @include box-shadow(0px 0px 3px rgba(0, 0, 0, 0.06));
      .ant-table-cell {
        margin: 0;
        text-align: left !important;

        &:first-child {
          padding: 0.5rem 0;
          padding-left: 1rem;
          border-left: 1px solid theme-colors("background", "black", 0.04);
        }
        &:last-child {
          padding-right: 1rem;
          border-right: 1px solid theme-colors("background", "black", 0.04);
        }
      }
    }
    .table-arrows {
      @extend .flexbox;
      @extend .item-center;

      svg {
        min-width: 10px;
        max-width: 10px;
        min-height: 10px;
        margin-right: 5px;
      }
    }
    .project-name {
      font-size: 16px;
      font-weight: 600;
      min-height: 55px;
      @extend .flexbox;
      @extend .item-center;
    }
    .update-text {
      font-size: 14px;
    }
    .no-responses {
      color: theme-colors("background", "black", 0.4);
    }
    .responses {
      font-size: 14px;
      color: theme-colors("background", "black", 0.3);
    }
    .live {
      background: theme-colors("accent", "green", 0.07);
      color: theme-colors("accent", "green");
      font-weight: 600;
      display: inline-block;
      font-size: 13px;
      padding: 1px 8px;
      @extend .radius-lg;
      &.draft {
        background: theme-colors("accent", "blue", 0.07);
        color: theme-colors("accent", "blue");
      }
      &.failed {
        background: theme-colors("background", "red", 0.07);
        color: theme-colors("background", "red");
      }
      &.processing {
        background: theme-colors("background", "orange", 0.07);
        color: theme-colors("background", "orange");
      }
    }
  }

  &.people-table {
    margin: 0;
    .ant-table-thead {
      margin: 0;
      color: #5c6970;
      .ant-table-cell {
        margin: 0;
        color: #5c6970;
        &:first-child {
          padding-left: 42px;
          min-width: 290px;
          max-width: 290px;
        }
      }
    }
    .ant-table-tbody {
      &:before,
      &:after {
        content: "";
        width: 42px;
        position: absolute;
        height: calc(100% - 48px);
        z-index: 9;
        top: 50px;
        background: theme-colors("background", "white");
        @include media(">=phone", "<=desktop") {
          display: none;
        }
        @include media("<phone") {
          display: none;
        }
      }
      &:before {
        left: 0;
        //border-right: 1px solid #ededed;
      }
      &:after {
        right: 0;
        //  border-left: 1px solid #ededed;
      }
      .ant-table-cell {
        outline: 0;
        &:first-child {
          padding-left: 42px;
        }
        &:last-child {
          padding-right: 42px;
          text-align: left;
        }
      }
    }
  }

  &.project-table {
    margin: 0;
    .ant-table-thead {
      margin: 0;
      color: #5c6970;
      .ant-table-cell {
        margin: 0;
        color: #5c6970;
        &:first-child {
          padding-left: 42px;
        }
      }
    }
    .ant-table-tbody {
      &:before,
      &:after {
        content: "";
        width: 42px;
        position: absolute;
        height: calc(100% - 48px);
        z-index: 9;
        top: 50px;
        background: theme-colors("background", "white");
        @include media(">=phone", "<=desktop") {
          display: none;
        }
        @include media("<phone") {
          display: none;
        }
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
      .ant-table-cell {
        outline: 0;
        &:first-child {
          padding-left: 42px;
          border-left: 1px solid #ededed;
        }
        &:last-child {
          padding-right: 42px;
          text-align: left;
          border-right: 1px solid #ededed;
        }
      }
    }
  }
  .table-count {
    font-size: 13px;
    color: #5c6970;
    line-height: 16px;
  }
}

/*--- Tag table end--- */
.ant-picker {
  min-height: 36px;
  border: 1px solid theme-colors("secondary", "light", 0.3);
  @include border-radius(5px);
}
/*--- Tag overide--- */
.ant-tag {
  min-height: 28px;
  padding: 0px 10px;
  margin: 5px;
  background-color: theme-colors("text", "link");
  color: theme-colors("background", "white");
  @extend .flexbox;
  @extend .item-center;
  @include border-radius(30px);
  border: none;
  @include media("<=tablet") {
    flex-wrap: wrap;
    padding: 6px;
  }
  span {
    flex: 0 0 auto;
    &:nth-child(1) {
      color: theme-colors("background", "white", 0.7);
    }
    &:nth-child(2) {
      padding: 0px 8px 0px 2px;
      color: theme-colors("background", "white", 0.6);
    }
    &:nth-child(3) {
      color: theme-colors("background", "white");
    }
    &.anticon-close {
      color: theme-colors("background", "white");
    }
  }
}
/*--- Tag overide Model window--- */
.ant-modal-wrap {
  height: 100vh;
  overflow: hidden;
  @extend .flexbox;
  @extend .all-center;
  @include transition(all 0.4s ease-in-out);
  @include animation("fadeIn 0.8s");
  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
      @include transform(scale(0));
    }

    100% {
      opacity: 1;
      @include transform(scale(1));
    }
  }
  .ant-modal {
    flex: 0 0 auto;
    margin: 0;
    top: auto;
    padding: 0;
    @include transform-origin(0px !important);
    @include media("<phone") {
      max-width: 90%;
      min-width: 90%;
    }
    @include media(">=phone", "<tablet") {
      max-width: 90% !important;
      min-width: 90% !important;
    }
    .ant-modal-content {
      background-color: theme-colors("background", "white");
      @extend .flex-column;
      @include border-radius(10px);
      .ant-modal-header {
        flex: 0 1 auto;
        min-width: 100%;
        @include border-radius(10px 10px 0 0);
      }
      .ant-modal-body {
        flex: 1 1 auto;
        min-width: 100%;
        padding: 32px 24px 32px 24px;
        .model-content-scroll {
          max-height: calc(100vh - 275px);
          margin-top: 10px;
        }
      }
      .ant-modal-footer {
        min-width: 100%;
        flex: 0 1 auto;
        padding: 10px 34px;
        @extend .flexbox;
        @extend .item-center;
        @extend .justify-end;
        @include border-radius(0 0 10px 10px);
      }

      .content-block {
        padding-right: 1rem;
      }
    }
    &.email-modal {
      min-width: 700px;
      max-width: 700px;
    }
    &.thank-you-modal {
      min-width: 650px;
      max-width: 650px;
    }
    &.project-modal {
      min-width: 820px;
      max-width: 820px;
      .project-content-scroll {
        max-height: calc(100vh - 100px);
        margin-top: 10px;
      }
      @include media(">=phone", "<tablet") {
        min-width: 85%;
        max-width: 85%;
      }

      @include media("<phone") {
        min-width: 85%;
        max-width: 85%;
      }
      .ant-modal-body {
        padding: 15px 25px 25px 25px !important;
      }
    }
    &.cancel-modal {
      min-width: 600x;
      max-width: 600px;
      .footer-block {
        @extend .flexbox;
        @extend .justify-end;
      }
      @include media(">=phone", "<tablet") {
        min-width: 85%;
        max-width: 85%;
      }

      @include media("<phone") {
        min-width: 85%;
        max-width: 85%;
      }
      .ant-modal-body {
        padding: 15px 25px 25px 25px !important;
      }
    }
    &.topic-modal {
      min-width: 800px;
      max-width: 800px;
      .add-topic {
        min-width: 100%;
        max-width: 100%;
        margin: 0.5rem 0rem;
      }
      .ant-modal-header {
        .ant-modal-title {
          @extend .heading-one;
          margin-right: 1rem;
        }
      }
      .project-content-scroll {
        max-height: calc(100vh - 100px);
        margin-top: 10px;
      }
      @include media(">=phone", "<tablet") {
        min-width: 85%;
        max-width: 85%;
      }

      @include media("<phone") {
        min-width: 85%;
        max-width: 85%;
      }
      .ant-modal-body {
        padding: 15px 25px 25px 25px !important;
      }
    }
    &.employee-modal {
      min-width: 650px;
      max-width: 650px;
      @include media(">=phone", "<tablet") {
        min-width: 85%;
        max-width: 85%;
      }

      @include media("<phone") {
        min-width: 85%;
        max-width: 85%;
      }
      .employee-content-scroll {
        max-height: calc(100vh - 180px);
        //margin-top: 10px;
      }
      .ant-modal-body {
        padding: 0px !important;
      }
      .ant-modal-footer {
        padding: 0px !important;
        border: none !important;
      }
    }
    &.publish-modal {
      min-width: 650px;
      max-width: 650px;
      @include media(">=phone", "<tablet") {
        min-width: 85%;
        max-width: 85%;
      }

      @include media("<phone") {
        min-width: 85%;
        max-width: 85%;
      }
      // .employee-content-scroll {
      //   max-height: calc(100vh - 180px);
      // }

      .ant-modal-header {
        .ant-modal-title {
          @extend .heading-one;
        }
      }
      .ant-modal-footer {
        border: none !important;
      }
    }
    &.manage-modal {
      min-width: 650px;
      max-width: 650px;
      .ant-modal-footer {
        @include media("<phone") {
          margin-bottom: 15px;
          width: 100%;
          display: block;
        }
        @include media(">=phone", "<=576px") {
          margin-bottom: 15px;
          width: 100%;
          display: block;
        }
      }
      .ant-btn {
        @include media("<phone") {
          margin-bottom: 15px;
          width: 100%;
          margin-left: 0px;
        }
        @include media(">=phone", "<=576px") {
          margin-bottom: 15px;
          width: 100%;
          margin-left: 0px;
        }
      }
    }

    &.invite-modal {
      min-width: 550px;
      max-width: 550px;
      .ant-modal-footer {
        padding-left: 24px;
        @extend .flexbox;
        @include media("<phone") {
          margin-bottom: 15px;
          width: 100%;
          display: block;
        }
        @include media(">=phone", "<=576px") {
          margin-bottom: 15px;
          width: 100%;
          display: block;
        }
        .invite-block {
          @extend .flexbox;
          &__left {
            margin-right: auto;
            @extend .flexbox;
            @extend .justify-start;
          }
          &__right {
            @extend .flexbox;
            @extend .justify-end;
          }
        }
      }
      .ant-btn {
        @include media("<phone") {
          margin-bottom: 15px;
          width: 100%;
          margin-left: 0px;
        }
        @include media(">=phone", "<=576px") {
          margin-bottom: 15px;
          width: 100%;
          margin-left: 0px;
        }
      }
    }
    &.email-response {
      min-width: 360px;
      max-width: 360px;
      span {
        margin-right: 7px;
        .anticon {
          svg {
            margin-top: -4px !important;
          }
        }
      }
      p {
        padding-left: 21px;
      }
      .ant-modal-footer {
        padding: 25px 0px;
      }
    }
    &.barchart-modal {
      min-width: 1000px;
      max-width: 1000px;
    }
  }
}
/*--- Tag overide END--- */
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-no-space {
  border-right: none !important;
}
/*--- filtertag-wrapper--- */
.filtertag-wrapper {
  .ant-menu-sub {
    width: auto !important;
    min-width: auto !important;
  }
}
.ant-popover-buttons {
  @extend .flexbox;
  @extend .all-center;
  .ant-btn {
    flex: 1 1 auto;
    margin: 0;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
/*--- pagination--- */
.ant-pagination {
  @extend .flexbox;
  @extend .item-center;
  @include border-radius(5px);
  background: theme-colors("background", "white");
  padding: 5px;

  @include media(">=phone", "<tablet") {
    padding: 3px;
    margin-top: 10px;
  }

  @include media("<phone") {
    padding: 3px;
    margin-top: 10px;
  }

  li {
    overflow: hidden;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
  .ant-pagination-disabled,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item {
    flex: 0 0 auto;
    margin: 0px 5px;
    min-width: 26px;
    max-height: 26px;
    min-height: 26px;
    font-weight: normal;
    border: none;
    @extend .flexbox;
    @extend .all-center;
    @include border-radius(5px);
    @include transition(all 0.8s ease-in-out);

    @include media(">=phone", "<tablet") {
      margin: 0px 0px;
      min-width: 23px;
      max-height: 23px;
      min-height: 23px;
    }

    @include media("<phone") {
      margin: 0px 0px;
      min-width: 23px;
      max-height: 23px;
      min-height: 23px;
    }

    &.ant-pagination-item-active,
    &:hover,
    &:focus {
      border-color: theme-colors("background", "teal-light");
      background-color: theme-colors("background", "teal-light", 0.8);
      color: theme-colors("background", "white");
      @include transition(all 0.2s ease-in-out);
      &:hover,
      &:focus {
        a {
          color: theme-colors("background", "white");
        }
      }
    }
    .ant-pagination-item-link {
      flex: 0 0 auto;
      line-height: 8px !important;
      border: 0;
      height: auto;
      background-color: transparent;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    color: theme-colors("background", "black", 0.5);
    flex: 0 0 auto;
    svg {
      &:hover,
      &:focus {
        border: 0;
        color: theme-colors("background", "black", 0.5);
        @include transition(all 0.2s ease-in-out);
      }
    }
  }
  .ant-pagination-disabled {
    background-color: none;
    border-color: none;
    &:hover,
    &:focus {
      background-color: none !important;
      border-color: theme-colors("background", "black-light", 0.5) !important;
    }
  }
}

/*--- .ant-select-dropdown --- */
.ant-select-dropdown {
  position: relative;
  max-width: 360px;
  //min-width: 300px !important;
  top: initial;

  @include media("<phone") {
    max-width: 100%;
    min-width: 60% !important;
  }

  @include media(">=phone", "<tablet") {
    max-width: 100%;
    min-width: 60% !important;
  }
  &.heading-dropdown {
    min-width: 300px !important;
    @include media("<phone") {
      min-width: 150px !important;
    }
    @include media(">=phone", "<tablet") {
      min-width: 150px !important;
    }
  }
  &.search-dropdown {
    min-width: 200px !important;
    @include media("<phone") {
      min-width: 150px !important;
    }
    @include media(">=phone", "<tablet") {
      min-width: 150px !important;
    }
  }
}
.ant-select-dropdown-menu-item-selected {
  color: theme-colors("background", "orange") !important;
}
.ant-select-item-option-selected {
  @extend .flexbox;
  @extend .item-center;
  &:not(.ant-select-item-option-disabled) {
    background: theme-colors("background", "black", 0.08) !important;
    color: theme-colors("background", "black", 0.9) !important;
    .ant-select-item-option-content {
      font-weight: 400;
    }
  }
  .ant-select-item-option-state {
    flex: 0 0 auto;
    @extend .flexbox;
    @extend .all-center;
  }
}
.ant-select-search--inline .ant-select-search__field {
  font-size: 16px;
}
.ant-select-selection-selected-value {
  padding-right: 18px;
  font-size: 16px;
}
.ant-menu-submenu-title {
  border-bottom: 1px solid theme-colors("background", "black", 0.1);
  line-height: 31px !important;
}
.ant-menu-item:active,
.ant-menu-submenu-title {
  margin: 0;
  padding-left: 18px !important;

  &:active,
  &:hover,
  &:hover {
    background-color: transparent;
    color: #5c6970;
  }
}
.ant-menu-item,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title {
  &:hover {
    background-color: none;
    color: theme-colors("background", "teal-light");
  }
}
.ant-dropdown-menu-item {
  @extend .flexbox;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: none;
}
.dot-menu {
  .ant-dropdown-menu-vertical {
    padding: 0px !important;
  }
  .ant-dropdown-menu {
    &-item {
      a {
        color: theme-colors("text", "primary");
      }
      &:hover,
      &:focus {
        a {
          color: theme-colors("background", "teal-lighter");
        }
      }
    }
  }
}
/*--- .ant Menu --- */
.ant-menu-item-group {
  margin-bottom: 20px;
  .ant-menu-item-group-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}
/*--- popover --- */
.ant-popover-inner-content {
  @include media("<=576px") {
    width: 260px;
  }
  .ant-menu {
    margin: 0;
    .ant-menu-submenu-arrow {
      display: none !important;
    }
    .ant-menu-submenu-vertical {
      //  border-bottom: 1px solid #e4e5e7;
      font-size: 16px;
      font-weight: 400;
      line-height: 31px;
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}
.ant-popover {
  margin: 0;
  position: relative;
  //max-width: 650px;
  max-width: 315px;
  .ant-popover-placement-bottomLeft {
    z-index: 99;
    @include media("<tablet") {
      left: 15px;
    }
  }
  &.edit-popover {
    width: 40px;
    cursor: pointer;
    @include media("<phone") {
      width:40px;
    }
    @include media(">=phone", "<tablet") {
      width:40px;
    }
    .ant-popover-inner{
      width: 40px !important;
    }
    .ant-popover-inner-content {
      margin: auto;
      width: 40px !important;
      @include media("<phone") {
        width: auto;
      }
      @include media(">=phone", "<tablet") {
        width: auto;
      }
    }
  }
  &.editsurvey-popover {
    width: 650px;
    cursor: pointer;
    @include media("<phone") {
      width:100%;
    }
    @include media(">=phone", "<tablet") {
      width:100%;
    }
    .ant-popover-inner{
      width: 650px !important;
    }
    .ant-popover-inner-content {
      margin: auto;
      width: 650px !important;
      @include media("<phone") {
        width: auto;
      }
      @include media(">=phone", "<tablet") {
        width: auto;
      }
    }
  }
  &.project-popover {
    max-width: 230px;
    @include media("<phone") {
      max-width: 195px;
    }
    @include media(">=phone", "<tablet") {
      max-width: 195px;
    }
    .ant-popover-inner-content {
      margin: 0px;
      @include media("<phone") {
        width: auto;
      }
      @include media(">=phone", "<tablet") {
        width: auto;
      }
    }
  }
  &.heatmap-popover {
    max-width: 300px;
    max-height: calc(100vh - 340px);
    overflow-y: auto;
    // .heatmap-content-scroll{

    // }
  }


}

/*--- checkbox radio wrapper START --- */
.ant-checkbox-wrapper,
.ant-radio-wrapper {
  @extend .flexbox;
  @extend .item-center;
  span {
    flex: 0 0 auto;
    margin: 0;
    padding: 0 0 0 10px;
    @include media("<=576px") {
      flex: auto;
    }
    &.ant-checkbox,
    &.ant-radio {
      padding: 0;
      top: auto;
      position: relative;
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      @extend .flexbox;
      @extend .all-center;
      .ant-checkbox-inner {
        padding: 0;
        min-width: 16px;
        min-height: 16px;
        max-width: 16px;
        max-height: 16px;
        border-color: theme-colors("primary", "base");
      }
      .ant-radio-inner {
        border-color: theme-colors("primary", "base");
      }
      &.ant-checkbox-checked {
        margin: 0;
        .ant-checkbox-inner {
          background-color: theme-colors("primary", "base");
          border-color: theme-colors("primary", "base");
        }
      }
      &.ant-radio-checked {
        margin: 0;
        .ant-radio-inner {
          border-color: theme-colors("primary", "base");
          &:after {
            background-color: theme-colors("primary", "base");
          }
        }
      }
    }
  }
}

/*--- checkbox radio wrapper END --- */
.demographics-radio-groups {
  margin-left: auto;
  @extend .flexbox;
  @extend .item-end;
  .ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      color: theme-colors("background", "orange");
      border: 1px solid theme-colors("background", "orange") !important;
      &::before {
        background: theme-colors("background", "orange");
      }
    }
  }
  .ant-radio-button-wrapper {
    &:hover {
      color: theme-colors("background", "orange") !important;
    }
  }
}
.edit-radiobtn {
  .ant-radio-inner {
    border-color: #497e7d !important;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid #497e7d !important;
    }
    &::after {
      background: #497e7d;
      width: 14px;
      height: 14px;
      left: 0px;
      top: 0px;
    }
  }
  .ant-radio-checked {
    &::after {
      border: 1px solid #497e7d;
    }
  }
}
.demo-radiobtn {
  row-gap: 1.5rem;
  @extend .flex-column;
  .ant-radio-wrapper {
    font-size: 20px;
  }
  .ant-radio-inner {
    background: #f6f6f4;
    border-color: #e7e8e9 !important;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid #497e7d !important;
    }
    &::after {
      background: #497e7d;
      width: 14px;
      height: 14px;
      left: 0px;
      top: 0px;
    }
  }
  .ant-radio-checked {
    &::after {
      border: 1px solid #497e7d;
    }
  }
}
/*--- Tooltip --- */
.ant-tooltip {
  padding: 0;
  @include transition(all 0.4s ease-in-out);
  .ant-tooltip-content {
    margin: 0;
    .ant-tooltip-arrow {
      bottom: -0.65rem;
      .ant-tooltip-arrow-content {
        width: 0.66rem;
        height: 0.66rem;
        background-color: #404040;
      }
    }
    .ant-tooltip-inner {
      min-height: 25px;
      padding: 0.25rem 0.5rem;
      color: theme-colors("background", "white");
      background-color: #404040;
      @include border-radius(5px);
      @include box-shadow(0 2px 8px rgba(0, 0, 0, 0.2));
    }
  }
  &.right-tooltip {
    .ant-tooltip-content {
      margin-left: 6px;
      .ant-tooltip-arrow {
        bottom: -0.65rem;
        left: -3px;
      }
    }
  }
  &.heatmap-tooltip {
    // top: 400px !important;
    .ant-tooltip-content {
      margin: 0;
      .ant-tooltip-arrow {
        display: none !important;
        bottom: -0.65rem;
        .ant-tooltip-arrow-content {
          width: 0.66rem;
          height: 0.66rem;
          background-color: theme-colors("background", "white") !important;
        }
      }
      .ant-tooltip-inner {
        min-width: 316px;
        min-height: 25px;
        padding: 0.25rem 0.5rem;
        color: theme-colors("background", "black");
        background-color: theme-colors("background", "white") !important;
        @include border-radius(5px);
        @include box-shadow(0 2px 8px rgba(0, 0, 0, 0.2));
      }
    }
  }
  &.heatmap-toptooltip {
    // top: 400px !important;
    .ant-tooltip-content {
      margin: 0;
      .ant-tooltip-arrow {
        display: none !important;
        bottom: -0.65rem;
        .ant-tooltip-arrow-content {
          width: 0.66rem;
          height: 0.66rem;
          background-color: theme-colors("background", "black") !important;
        }
      }
      .ant-tooltip-inner {
        min-width: 316px;
        min-height: 25px;
        padding: 0.25rem 0.5rem;
        color: theme-colors("background", "white");
        background-color: theme-colors("background", "black") !important;
        @include border-radius(5px);
        @include box-shadow(0 2px 8px rgba(0, 0, 0, 0.2));
      }
    }
  }
  &.heatmap-toptooltip {
    // top: 400px !important;
    .ant-tooltip-content {
      margin: 0;
      .ant-tooltip-arrow {
        display: none !important;
        bottom: -0.65rem;
        .ant-tooltip-arrow-content {
          width: 0.66rem;
          height: 0.66rem;
          background-color: theme-colors("background", "black") !important;
        }
      }
      .ant-tooltip-inner {
        min-width: 316px;
        min-height: 25px;
        padding: 0.25rem 0.5rem;
        color: theme-colors("background", "white");
        background-color: theme-colors("background", "black") !important;
        @include border-radius(5px);
        @include box-shadow(0 2px 8px rgba(0, 0, 0, 0.2));
      }
    }
  }
  &.heatmap-toptooltip {
    // top: 400px !important;
    .ant-tooltip-content {
      margin: 0;
      .ant-tooltip-arrow {
        display: none !important;
        bottom: -0.65rem;
        .ant-tooltip-arrow-content {
          width: 0.66rem;
          height: 0.66rem;
          background-color: theme-colors("background", "black", 0.7) !important;
        }
      }
      .ant-tooltip-inner {
        min-width: auto;
        min-height: 25px;
        padding: 0.25rem 0.5rem;
        color: theme-colors("background", "white");
        background-color: theme-colors("background", "black", 0.7) !important;
        @include border-radius(5px);
        @include box-shadow(0 2px 8px rgba(0, 0, 0, 0.2));
      }
    }
  }
}

/*--- Leftmenu --- */

.ant-menu-inline {
  border-right: 0px;
  .ant-menu-item {
    @extend .flexbox;
    @extend .item-center;
    margin-top: 0px;
    margin-bottom: 0px;
    @extend .running-text;
    line-height: 19.36px;
    padding: 5px 18px 5px 16.5px !important;
    color: theme-colors("text", "primary");
    white-space: normal;
    height: auto;
    min-height: 40px;
    margin-left: 0px;
    &::after {
      left: 0;
      right: auto;
      border-left: 5px solid theme-colors("accent", "secondary") !important;
    }

    &.ant-menu-item-selected {
      background-color: transparent;
      font-weight: 600;
    }
    &.result-header {
      position: relative;
      margin-bottom: 20px !important;
      padding-bottom: 15px;
      &:after {
        bottom: 10px;
      }
      &:before {
        display: block;
        position: absolute;
        bottom: 0px;
        left: 19px;
        right: 16px;
        content: "";
        border-bottom: 1px solid theme-colors("border", "primary");
      }
      .title-item {
        min-height: 30px;
        @extend .flexbox;
        @extend .item-center;
      }
    }
  }
  .ant-menu-submenu {
    font-size: 16px;
  }
  .ant-menu-sub {
    background: none;
    // border-bottom: 1px solid #e4e5e7;
    padding: 0px 1px !important;

    .ant-menu-item {
      border: none !important;
    }
  }
}
.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-menu-vertical-right,
.ant-menu-inline {
  .ant-menu-item {
    @extend .flexbox;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    &::after {
      border: none;
    }
  }
}
.newdashboard-menu {
  border-left: 0px !important;

  .ant-menu-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    height: auto !important;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";

    span {
      line-height: 22px !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: clip;
      //font-size: 14px;
    }
    .ant-menu-submenu-title {
      padding: 5px 18px 10px 16.5px !important;
    }
    &.ant-menu-sub,
    &.ant-menu-inline {
      padding: 0px !important;
      margin-left: 0px !important;
    }
    &::after {
      left: 0;
      right: auto;
      border-left: none !important;
    }
    // &:hover{
    // 	background-color: #FEE090;
    // 	color:#D73027 ;
    // 	font-weight: 600;
    // }

    .ant-menu-inline {
      .ant-menu-submenu-title {
        height: auto;
        padding-left: 16.5px !important;
        color: theme-colors("background", "black") !important ;

        .ant-menu-submenu-arrow {
          color: theme-colors("background", "black") !important ;
          &:hover {
            color: theme-colors("background", "black") !important;
          }
        }
      }
    }
    &.ant-menu-item-selected,
    & .ant-menu-submenu-selected {
      @extend .radius-sm;
      background-color: #fee090;
      color: #d73027;
      font-weight: 600;
    }
  }
  .ant-menu-submenu-selected {
    color: theme-colors("background", "black") !important;
  }
}

.editsurvy-menu {   
  border-right: none !important;
 // width: 300px;
 padding-top: 1.5rem;
  .ant-tabs-tab-btn{
    width: 300px;
  }
  
  .ant-tabs-nav{
    width: 300px;
  }
  .ant-tabs-nav-list{
    display: flex !important;
    flex-direction: column !important;
    padding-right: 1rem;
  }
  .ant-tabs-tab{
    background:  #fce7d8;
    border-radius:8px;
    width: 100%;
    padding: 8px 14px !important;
    &:hover{
      background-color: #FEE090;
       color:#f19a5a ;
      font-weight: 400 !important;
       }
  }
  .ant-tabs-tab-active{
    background-color: #fee090;
    color:#f19a5a ; 
    border: none !important;
    .ant-tabs-tab-btn {
      font-weight: 400 !important;
      padding: 0px 14px;
      border: none !important;
    }
   
  }
  .ant-menu-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    height: auto !important;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";

    span {
      line-height: 22px !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: clip;
      //font-size: 14px;
    }
    .ant-menu-submenu-title {
      padding: 5px 18px 10px 16.5px !important;
    }
    &.ant-menu-sub,
    &.ant-menu-inline {
      padding: 0px !important;
      margin-left: 0px !important;
    }
    &::after {
      left: 0;
      right: auto;
      border-left: none !important;
    }
  &:hover{
   background-color: #FEE090;
  	color:#D73027 ;
   font-weight: 400 !important;
    }

    .ant-menu-inline {
      .ant-menu-submenu-title {
        height: auto;
        padding-left: 16.5px !important;
        color: theme-colors("background", "black") !important ;

        .ant-menu-submenu-arrow {
          color: theme-colors("background", "black") !important ;
          &:hover {
            color: theme-colors("background", "black") !important;
          }
        }
      }
    }
    &.ant-menu-item-selected,
    & .ant-menu-submenu-selected {
      @extend .radius-sm;
      background-color: #fee090;
      color: #d73027;
      font-weight: 400 !important;
  
    }
  }
  .ant-menu-submenu-selected {
    color: theme-colors("background", "black") !important;
  }
}
.trend-surveys-menu{
  .ant-menu-item{
    &.ant-menu-item-selected,
    & .ant-menu-submenu-selected {
      @extend .radius-sm;
      background-color:rgba(0, 109, 124, 0.164);
      color: #006D7CFF;
      font-weight: 450;
    }
}
}

.trend-survey-search{
  margin-top: .5rem;
  border-radius: 5px;
  &:hover,&:focus{
    border-color: rgba(0, 109, 124, 0.473);
  }
}

.trend-surveys-tag{
  flex-wrap: wrap;
  display: flex;
  height: 40px;
  min-width: 100px;
  border-radius: 5px !important;
  max-width: 300px;
  padding-left: 5px ;
  padding-right: 0 !important;
  background-color: white;
  justify-content: space-between !important;
  border: 1px solid theme-colors('border', 'primary');
  .ant-tag-close-icon{
    padding-right: 0 !important;
    color: rgba(255, 0, 0, 0.603) !important;
    svg{
        height: 35px !important;
  }
 }
}

/*--- collapse --- */

.ant-collapse {
  @extend .flex-column;
  background: none;
  border: none;
  .ant-collapse-item {
    margin: 2px 20px 20px 10px;
    border: 1px solid #e1e1e1;
    margin-top: 2px;
    background-color: white;
    flex: 1 1 auto;
    @extend .flex-column;

    @include media(">=phone", "<tablet") {
      margin: 0px;
    }
    @include media("<phone") {
      margin: 0px;
    }

    .ant-collapse-header {
      flex: 1 1 auto;
      padding: 8px 10px;
      cursor: pointer;

      .ant-collapse-arrow {
        top: 40%;
        font-size: 13px;
        @include transform(translate(0, -50%));
      }

      .dimension {
        padding-left: 26px;
        flex: 1 1 auto;
        height: 30px;
        cursor: pointer;
        @extend .flexbox;
        @extend .item-center;
        @extend .justify-between;
        .ant-form-item {
          width: 300px;
        }
        h2 {
          font-size: 14px;
          margin: 0;
          font-weight: 600;
          color: #555544;
        }
      }
    }

    .ant-collapse-content {
      border: none;
      flex: 1 1 auto;

      .ant-collapse-content-box {
        padding: 0;
        .question-wrapper {
          @extend .flexbox;
          border: 1px solid #f2f2f2;
          margin: 8px;
          padding: 10px;
          box-shadow: 3px 5px rgb(250, 250, 250);

          &.active {
            background-color: #f2f5f8;
            cursor: auto;
            .question-pane__move-control {
              display: block;
            }
          }

          .left-control {
            padding: 0 5px 0 0;
            margin: 0 5px 0 0;
            height: 100%;
            line-height: 32px;
          }

          .question-pane {
            padding-bottom: 0px;
            flex: 1 1 auto;
            display: flex;
            overflow-x: auto;
            overflow: hidden;
            @include transition(all 0.2s ease-in-out);
            cursor: pointer;
            @extend .flex-column;
            &:last-child {
              margin-bottom: 0;
            }
            &__move-control {
              display: none;
              position: absolute;
              left: -30px;
              margin-top: 34px;
              transform: rotate(-90deg);
            }

            &__head {
              @extend .flexbox;
              @extend .item-center;
              @extend .justify-between;
              padding: 0px 0 10px 0;

              h3 {
                margin: 0;
                flex: 1 1 auto;
              }
              .question-label {
                margin-top: 0px;
                margin-bottom: 20px;
                .editable-field {
                }
              }

              .question-control {
                flex: 0 0 auto;
                width: 30px;
                height: 30px;
                cursor: pointer;
                @extend .flexbox;
                @extend .all-center;
              }
            }

            &__options {
              overflow-x: auto;

              .ant-radio-group {
                @include media(">=phone", "<tablet") {
                  display: inline-block !important;
                }
                @include media("<phone") {
                  display: inline-block !important;
                }

                .radio-button {
                  margin-right: 20px;
                  @extend .flexbox;
                  @extend .item-center;
                  margin-bottom: 20px;
                  .ant-form-item {
                    width: 70%;
                  }

                  &:last-child {
                    // margin-bottom: 0;
                  }
                }
              }
            }
          }
          .matrix-questions {
            margin-left: 23px;
            margin-bottom: 20px;
            display: flex;
            width: 100%;
            .matrix-text-field {
              width: 90%;
            }
            .anticon-close {
              font-size: 9px;
              align-self: center;
            }
            .anticon-menu {
              align-self: center;
              margin-left: 4px;
              margin-right: 5px;
              cursor: move;
              font-size: 11px;
            }
          }
        }
        .add-question {
          margin: 10px;
          @extend .flexbox;
          @extend .all-center;
          button {
            color: white;
            background-color: green;
            background-image: none;
            height: 40px;
            margin: 1px;
            svg {
              width: 10px;
              margin: 0px 3px;
              fill: theme-colors("background", "white");
            }
          }
        }
        .ant-upload-picture-card-wrapper {
          zoom: 2;
          @extend .flexbox;
          justify-content: center;
        }
        .ant-upload-select-picture-card {
          margin-right: 0px;
        }
      }
    }
  }
  &.topics-collaps {
    margin: 0;
    .ant-collapse-item {
      margin: 0 0 20px 0;
      border-color: theme-colors("border", "light");
      @include border-radius(6px);
      @include box-shadow(0 0px 8px rgba(0, 0, 0, 0.06));
      .ant-collapse-header {
        padding: 10px 20px;
        @include media("<phone") {
          padding: 5px 8px;
        }
        @include media(">=phone", "<tablet") {
          padding: 5px 8px;
        }
        .ant-collapse-arrow {
          left: auto;
          right: 23px;
          @include media("<phone") {
            right: 10px;
          }
          @include media(">=phone", "<tablet") {
            right: 10px;
          }
        }
      }
      .ant-collapse-content {
        padding: 20px;
        margin-bottom: 1rem;
        .ant-collapse-content-box {
          padding-top: 30px;
          border-top: 1px solid #ddd;
        }
      }
    }
  }


  &.list-collaps {
    margin: 0;
    .ant-collapse-item {
      margin: 0 0 20px 0;
      border-color: theme-colors("border", "light");
      @include border-radius(6px);
      @include box-shadow(0 0px 8px rgba(0, 0, 0, 0.06));
      .ant-collapse-header {
        padding: 20px 20px;
         h1{
          font-size: 18px;
         }
         @include media("<phone") {
          padding: 5px 8px;
        }
        @include media(">=phone", "<tablet") {
          padding: 5px 8px;
        }
        // .ant-collapse-arrow {
        //   left: auto;
        //   right:50px;

        //     @include media("<phone") {
        //      right: 10px;
        //   }
        //   @include media(">=phone", "<tablet") {
        //     right: 10px;
        //   }
        // }
      }
      .ant-collapse-content {
        padding: 20px;
        margin-bottom: 1rem;
        .ant-collapse-content-box {
          padding-top: 30px;
          border-top: 1px solid #ddd;
        }
      }
    }
  }


  &.trend-collaps {
    margin: 0;
    margin-right: 1rem;
    .ant-collapse-item {
      margin: 0 0 20px 0;
      .ant-collapse-header {
      
        .ant-collapse-arrow {
          left: auto;
          right: 23px;
          @include media("<phone") {
            right: 10px;
          }
          @include media(">=phone", "<tablet") {
            right: 10px;
          }
        }
      }
      
    }
  }
  &.survey-topics {
    margin-bottom: 1.5rem;
    .ant-collapse-item {
      margin: 0 0px 0px 0px !important;
      border: none;
      border-bottom: 1px solid theme-colors("border", "light");
      @include border-radius(0px);
      @include box-shadow(0 0px 0px rgba(0, 0, 0, 0.06));
      .ant-collapse-header {
        padding: 10px 0px;
        font-weight: 600;
        font-size: 17px;
        @include media("<phone") {
          padding: 5px 8px;
        }
        @include media(">=phone", "<tablet") {
          padding: 5px 8px;
        }
        .ant-collapse-arrow {
          left: auto;
          right: 5px;
          @include media("<phone") {
            right: 5px;
          }
          @include media(">=phone", "<tablet") {
            right: 5px;
          }
        }
      }
      .ant-collapse-content {
        padding: 10px;
        .ant-collapse-content-box {
          padding-top: 0px;
          border-top: none;
        }
      }
    }
  }
}
.ant-select-tree {
  position: relative;
  .ant-select-tree-node-content-wrapper {
    padding: 2px 4px;
  }
  .ant-select-tree-node-selected {
    background: none !important;
  }
}
.ant-steps-item-tail {
  &::after {
    background: theme-colors("accent", "yellow");
  }
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: theme-colors("accent", "yellow");
}
.ant-steps {
  @extend .flexbox;
  @extend .item-center;

  &-item {
    flex: 1 1 auto;
    @extend .flexbox;
    @extend .item-center;
    &-description {
      font-weight: 400;
    }
    &-tail {
      &::after {
        background: theme-colors("accent", "yellow");
      }
    }
    &-content {
      width: 125px !important;
    }
    &.ant-steps-item-process {
      padding: 0;

      .ant-steps-item-icon {
        background: theme-colors("accent", "yellow");
        border: solid 2px theme-colors("accent", "yellow");
      }
    }

    &-icon {
      border: solid 2px #333;
      @extend .flexbox;
      @extend .all-center;
      @include border-radius(50%);
    }
  }
}
.ant-select-selection-overflow-item {
  @extend .item-nowrap;
}
/*--- progres bar --- */
.ant-progress {
  flex: 0 1 auto;
  column-gap: 0.5rem;
  @extend .flexbox;
  @extend .item-center;
  .ant-progress-outer {
    padding: 0 !important;
    margin: 0 !important;
    flex: 1 1 auto;
  }
  .ant-progress-text {
    flex: 0 0 auto;
    width: auto;
    margin: 0 !important;
  }
  &.answer-progressbar {
    flex: 0 1 auto;
    .ant-progress-inner {
      min-width: 150px;
      height: 8px;
      background-color: theme-colors("primary", "base", 0.3);
      .ant-progress-bg {
        background-color: theme-colors("primary", "base");
      }
    }
  }
}
/*--- progres bar END --- */
