/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: font-url("public/fonts/Inter-Thin.woff2") format("woff2"), font-url("public/fonts/Inter-Thin.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: font-url("public/fonts/Inter-ExtraLight.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: font-url("public/fonts/Inter-Light.woff2") format("woff2"), font-url("public/fonts/Inter-Light.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: font-url("public/fonts/Inter-Regular.woff2") format("woff2"), font-url("public/fonts/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: font-url("public/fonts/Inter-Medium.woff2") format("woff2"), font-url("public/fonts/Inter-Medium.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: font-url("public/fonts/Inter-SemiBold.woff2") format("woff2"), font-url("public/fonts/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: font-url("public/fonts/Inter-Bold.woff2") format("woff2"), font-url("public/fonts/Inter-Bold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: font-url("public/fonts/Inter-ExtraBold.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  src: font-url("public/fonts/Inter-Black.woff2") format("woff2"), font-url("public/fonts/Inter-Black.woff") format("woff"); }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-column, .demographicradio-block, .demographicradio-block__header, .demographicradio-block__content .answer-generation__element {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.all-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.btn-gradient {
  background-image: -webkit-linear-gradient(legacy-direction(to right), #35807e, #35807e);
  background-image: -webkit-linear-gradient(to right, #35807e, #35807e);
  background: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: linear-gradient(to right, #35807e, #35807e); }

.radius-xs {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem; }

.radius-sm {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem; }

.radius-md, .demographicradio-block__content .answer-generation__element .generation-item {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem; }

.radius-lg {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem; }

.radius-xl {
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem; }

.radius-xxl {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem; }

/* ============= Typographt ==============*/
.heading-one {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #29363d; }

.heading-two {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29363d; }

.heading-three {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #29363d; }

.running-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5c6970; }

.small-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8f9ca3; }

.link-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #4799eb; }

/* ============= Typographt END ==============*/
.demographics-main {
  display: flex; }
  .demographics-main .demographics-image {
    width: 50%;
    max-height: 75vh;
    margin: 0rem 1rem; }
    .demographics-main .demographics-image img {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      object-fit: cover;
      object-position: 50% 50%;
      height: 100% !important; }
  .demographics-main.flex-direction {
    display: flex;
    flex-direction: row-reverse; }

.demographicradio-block {
  row-gap: 1.5rem; }
  .demographicradio-block.width-multioption {
    width: 50%; }
  .demographicradio-block__header {
    row-gap: 0.5rem;
    flex: 0 1 auto; }
    .demographicradio-block__header .heading-asterisk p:last-child::after {
      position: relative;
      content: "*";
      color: #f52847;
      font-size: 14px;
      font-weight: 700;
      top: -5px; }
    .demographicradio-block__header h3 {
      display: flex;
      font-size: 24px;
      font-weight: 400; }
      @media (min-width: 992px) {
        .demographicradio-block__header h3 {
          font-size: 26px; } }
    .demographicradio-block__header h5 {
      font-size: 14px;
      color: #8c8c8c; }
  .demographicradio-block__content .otherOption-textInput {
    padding-top: 0rem;
    max-width: 100%; }
  .demographicradio-block__content .answer-generation {
    row-gap: 1rem;
    max-height: 350px; }
    .demographicradio-block__content .answer-generation__element {
      flex: 0 0 auto;
      margin-bottom: 1rem; }
      .demographicradio-block__content .answer-generation__element .generation-item {
        font-size: 20px;
        flex: 0 0 auto;
        margin: 0;
        padding: 0.5rem 0.75rem;
        border: solid 1px rgba(92, 105, 112, 0.3);
        background-color: rgba(92, 105, 112, 0.1);
        transition-property: all;
        transition-duration: 0.4s;
        animation-duration: 0.7s; }
        @media (min-width: 1025px) {
          .demographicradio-block__content .answer-generation__element .generation-item {
            max-width: 100%;
            height: auto; } }
        .demographicradio-block__content .answer-generation__element .generation-item:hover {
          border-color: #007C89CC; }
      .demographicradio-block__content .answer-generation__element.item-active {
        flex: 0 0 auto;
        font-weight: 500; }
        .demographicradio-block__content .answer-generation__element.item-active .generation-item {
          border-color: rgba(53, 128, 126, 0.8);
          background-color: rgba(53, 128, 126, 0.1); }

.answer-text {
  word-break: break-all;
  width: 100%;
  min-width: 200px;
  display: block;
  white-space-collapse: break-spaces;
  word-spacing: initial;
  white-space: pre-wrap;
  /* word-break: keep-all; */
  word-wrap: break-word;
  padding-left: 3px !important; }
  @media (min-width: 460px) {
    .answer-text {
      max-width: 465px;
      min-width: 200px;
      white-space: break-spaces;
      word-break: break-word; } }
