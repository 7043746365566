/*--- Dropdown overide--- */
.dropdown {
  flex: 0 0 auto;
  cursor: pointer;
  @extend .flex-column;
  @extend .all-center;
  .dropdown-toggle {
    flex: 0 0 auto;
    @extend .flexbox;
    @extend .all-center;
    &:before,
    &:after {
      display: none !important;
    }
  }
  .dropdown-menu {
    margin: 0;
    padding: 0px;
    border: 1px solid theme-colors('background', 'black', 0.15);
    display: block !important;
    right: 0 !important;
    left: auto !important;
    z-index: 99;
    top: 100% !important;
    min-width: 180px;
    overflow: hidden;
    transform-origin: top right;
    background-color: theme-colors('background', 'white');
    @include transform(scale(0) translate(0) !important);
    @include transition(all 0.4s ease-in-out);
    @include box-shadow(0px 0px 20px rgba(0, 0, 0, 0.2), 0px 0px 1px 1px rgba(0, 0, 0, 0.01));
    @include media('<phone') {
      min-width: 100%;
    }
    @include media('>=phone', '<=575.99px') {
      min-width: 100%;
    }
    @include media('>=576px', '<tablet') {
      min-width: 100%;
    }

    &.show {
      margin: 0;
      overflow: visible;
      @include transform(scale(1) translate(2px, 0) !important);
    }
    .dropdown-item {
      margin: 0;
      padding: 0;
      @include transition(all 0.4s ease-in-out);
      &:hover,
      &:active,
      &:focus {
        background: #eee;
        @include transition(all 0.4s ease-in-out);
        a {
          color: #ff903c;
          @include transition(all 0.4s ease-in-out);
        }
      }
      a {
        padding: 8px 10px;
        font-size: 13px;
        color: theme-colors('background', 'black', 0.6);
        @extend .flexbox;
        @extend .item-center;
        @include transition(all 0.4s ease-in-out);
        span {
          flex: 0 0 auto;
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
/*--- Card--- */
.card {
  @extend .flex-column;
  .card-body {
    @extend .flex-column;
  }
  .card-footer {
    @extend .flexbox;
    @extend .item-center;
  }
}
