/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: font-url("public/fonts/Inter-Thin.woff2") format("woff2"), font-url("public/fonts/Inter-Thin.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: font-url("public/fonts/Inter-ExtraLight.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: font-url("public/fonts/Inter-Light.woff2") format("woff2"), font-url("public/fonts/Inter-Light.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: font-url("public/fonts/Inter-Regular.woff2") format("woff2"), font-url("public/fonts/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: font-url("public/fonts/Inter-Medium.woff2") format("woff2"), font-url("public/fonts/Inter-Medium.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: font-url("public/fonts/Inter-SemiBold.woff2") format("woff2"), font-url("public/fonts/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: font-url("public/fonts/Inter-Bold.woff2") format("woff2"), font-url("public/fonts/Inter-Bold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: font-url("public/fonts/Inter-ExtraBold.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  src: font-url("public/fonts/Inter-Black.woff2") format("woff2"), font-url("public/fonts/Inter-Black.woff") format("woff"); }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-column, .admin-tab, .admin-tab .analytics-reports {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flexbox, .admin-tab .input-form__header, .admin-tab .input-form__body--section .drag-section, .admin-tab .input-form__body--section .input-file, .admin-tab .analytics-reports .batch-operations, .admin-tab .analytics-reports .batch-operations .select-operation {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.all-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center, .admin-tab .input-form__body--section .drag-section {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .admin-tab .input-form__body--section .drag-section {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.btn-gradient {
  background-image: -webkit-linear-gradient(legacy-direction(to right), #35807e, #35807e);
  background-image: -webkit-linear-gradient(to right, #35807e, #35807e);
  background: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: linear-gradient(to right, #35807e, #35807e); }

.radius-xs {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem; }

.radius-sm {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem; }

.radius-md {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem; }

.radius-lg {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem; }

.radius-xl {
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem; }

.radius-xxl {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem; }

/* ============= Typographt ==============*/
.heading-one {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #29363d; }

.heading-two {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29363d; }

.heading-three {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #29363d; }

.running-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5c6970; }

.small-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8f9ca3; }

.link-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #4799eb; }

/* ============= Typographt END ==============*/
.addreports-wrapper {
  border: 0px; }
  .addreports-wrapper .card-body {
    padding: 24px 0px; }

@media (min-width: 320px) and (max-width: 1024px) {
  .addreports-wrapper .ant-table-wrapper {
    width: 100%;
    overflow-x: scroll; } }

.admin-tab .input-form {
  margin-bottom: 20px; }
  .admin-tab .input-form__header {
    margin-bottom: 20px;
    margin-top: 20px; }
    .admin-tab .input-form__header h3 {
      font-size: 16px;
      color: #ff7712; }
      .admin-tab .input-form__header h3 sup {
        font-size: 11px;
        font-weight: 400; }
  .admin-tab .input-form__body {
    margin: 0; }
    @media (min-width: 768px) {
      .admin-tab .input-form__body--section .drag-section {
        -webkit-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: start;
        -moz-align-items: start;
        align-items: start; } }

@media (min-width: 320px) and (max-width: 767px) {
  .admin-tab .input-form__body--section .drag-section {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; } }
    .admin-tab .input-form__body--section .drag-section .download-links {
      margin-left: 26px !important;
      margin-bottom: 20px; }
      .admin-tab .input-form__body--section .drag-section .download-links .download-link {
        cursor: pointer;
        display: inline-block;
        margin-left: 6px;
        color: #4799eb;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out; }
        .admin-tab .input-form__body--section .drag-section .download-links .download-link:hover, .admin-tab .input-form__body--section .drag-section .download-links .download-link:focus {
          color: #ff903c;
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out; }
      .admin-tab .input-form__body--section .drag-section .download-links .download-loader {
        margin-left: 5px; }
    .admin-tab .input-form__body--section .instructions-wrappper {
      margin-top: 20px; }
    .admin-tab .input-form__body--section .download-instructions, .admin-tab .input-form__body--section .download-heading {
      margin-left: 0px;
      margin-bottom: 20px;
      font-size: 15px;
      display: block; }
    .admin-tab .input-form__body--section .download-heading {
      font-weight: bold;
      font-size: 17px; }
    .admin-tab .input-form__body--section .instructions {
      list-style-type: decimal;
      list-style-position: outside;
      margin-left: 20px;
      margin-bottom: 20px;
      font-size: 13px;
      display: list-item;
      max-width: 550px;
      line-height: 1.6; }
    .admin-tab .input-form__body--section ol ol .instructions {
      list-style-type: lower-alpha; }
    .admin-tab .input-form__body--section .input-file__hidden {
      width: 100%; }
    .admin-tab .input-form__body--section .input-file__btn {
      width: 100%;
      background-color: #5cb68a;
      border-color: #5cb68a;
      color: #fff;
      justify-content: left; }
    .admin-tab .input-form__body--section .input-file__details {
      color: green;
      margin-left: 10px;
      font-size: 13px;
      line-height: 32px;
      font-weight: 300;
      position: absolute;
      top: 34px;
      left: 0px;
      max-width: 100%;
      overflow: hidden; }
    .admin-tab .input-form__body--section .enter-batchid {
      width: 100%; }

.admin-tab .list-batchids {
  margin-top: 30px; }
  .admin-tab .list-batchids .description {
    color: #d3d3d3; }

.admin-tab .analytics-reports h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 15px 0;
  color: #494949; }

.admin-tab .analytics-reports .batch-operations {
  flex-direction: row;
  align-items: center;
  margin: 0 10px 10px 0; }
  .admin-tab .analytics-reports .batch-operations .select-operation {
    margin-right: 15px; }
    .admin-tab .analytics-reports .batch-operations .select-operation > span {
      padding: 5px;
      border: 1px solid #5cb68a;
      cursor: pointer; }
      .admin-tab .analytics-reports .batch-operations .select-operation > span:hover {
        color: #fff;
        background-color: #5cb68a; }
    .admin-tab .analytics-reports .batch-operations .select-operation :first-child {
      border-right: none;
      border-radius: 5px 0 0 5px; }
    .admin-tab .analytics-reports .batch-operations .select-operation :last-child {
      border-right: 1px solid #5cb68a;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
  .admin-tab .analytics-reports .batch-operations .render-operator {
    margin-right: 15px; }
  .admin-tab .analytics-reports .batch-operations > i {
    font-size: 20px; }
    .admin-tab .analytics-reports .batch-operations > i :hover {
      border: 3px solid #9b9b9b;
      background-color: #9b9b9b;
      border-radius: 50%;
      color: #fff; }

.timezone-picker {
  width: 300px; }

.reprocess-btn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.break-word {
  word-break: break-all; }

.select-column-modal .ant-modal-body {
  height: 600px;
  margin-top: -80px;
  overflow: auto; }

.select-column-modal .ant-modal-close-x {
  line-height: normal;
  text-align: right;
  margin-top: 2px;
  height: auto;
  width: auto;
  margin-right: 7px; }

.table-multiselect {
  width: 160px; }
