/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: font-url("public/fonts/Inter-Thin.woff2") format("woff2"), font-url("public/fonts/Inter-Thin.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: font-url("public/fonts/Inter-ExtraLight.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: font-url("public/fonts/Inter-Light.woff2") format("woff2"), font-url("public/fonts/Inter-Light.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: font-url("public/fonts/Inter-Regular.woff2") format("woff2"), font-url("public/fonts/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: font-url("public/fonts/Inter-Medium.woff2") format("woff2"), font-url("public/fonts/Inter-Medium.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: font-url("public/fonts/Inter-SemiBold.woff2") format("woff2"), font-url("public/fonts/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: font-url("public/fonts/Inter-Bold.woff2") format("woff2"), font-url("public/fonts/Inter-Bold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: font-url("public/fonts/Inter-ExtraBold.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  src: font-url("public/fonts/Inter-Black.woff2") format("woff2"), font-url("public/fonts/Inter-Black.woff") format("woff"); }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-column, .matrix-wrapper, .matrix-wrapper__top--head, .matrix-wrapper .matrix-block, .matrix-wrapper .matrix-block__area .matrixbox-wrapper, .matrix-wrapper .matrix-block__area--list li, .matrix-wrapper .matrix-block__area--list li .box-color, .matrix-wrapper .matrix-block__area--activecheck, .matrix-wrapper .matrix-block__area--activecheck .active-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flexbox, .matrix-wrapper .matrix-block__area, .matrix-wrapper .matrix-block__area .text-survey, .matrix-wrapper .matrix-block__area--list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.all-center, .matrix-wrapper .matrix-block__area--list li .box-color, .matrix-wrapper .matrix-block__area--activecheck {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between, .matrix-wrapper, .matrix-wrapper .matrix-block__area .text-survey {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start, .matrix-wrapper .matrix-block__area, .matrix-wrapper .matrix-block__area .matrixbox-wrapper, .matrix-wrapper .matrix-block__area--list {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap, .matrix-wrapper .matrix-block__area--list {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.btn-gradient {
  background-image: -webkit-linear-gradient(legacy-direction(to right), #35807e, #35807e);
  background-image: -webkit-linear-gradient(to right, #35807e, #35807e);
  background: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: linear-gradient(to right, #35807e, #35807e); }

.radius-xs {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem; }

.radius-sm {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem; }

.radius-md, .matrix-wrapper .matrix-block__area--list li .box-color, .matrix-wrapper .matrix-block__area--activecheck .active-item {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem; }

.radius-lg {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem; }

.radius-xl {
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem; }

.radius-xxl {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem; }

/* ============= Typographt ==============*/
.heading-one {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #29363d; }

.heading-two {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29363d; }

.heading-three {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #29363d; }

.running-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5c6970; }

.small-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8f9ca3; }

.link-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #4799eb; }

/* ============= Typographt END ==============*/
.matrix-main {
  display: flex; }
  .matrix-main .matrix-image {
    width: 50%;
    height: 100vh; }
    .matrix-main .matrix-image img {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      object-fit: cover;
      object-position: 50% 50%;
      height: 100% !important; }
  .matrix-main.flex-direction {
    display: flex;
    flex-direction: row-reverse; }

.matrix-wrapper {
  grid-row-gap: 2rem;
  row-gap: 2rem; }
  .matrix-wrapper__top {
    width: 100%; }
    .matrix-wrapper__top h2 {
      font-weight: 600;
      font-size: 24px; }
    .matrix-wrapper__top--head {
      margin-bottom: 3.5rem; }
      .matrix-wrapper__top--head h2 {
        font-weight: 700;
        font-size: 30px; }
      .matrix-wrapper__top--head h5 {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0px !important; }
  .matrix-wrapper__bottom {
    width: 100%; }
  .matrix-wrapper.width-matrix {
    width: 50%; }
  .matrix-wrapper .matrix-scroll {
    max-height: calc(100vh - 340px);
    margin: 1rem; }
  .matrix-wrapper .required-icon svg {
    min-width: 8px !important;
    max-width: 8px !important;
    margin-left: 5px !important; }
  .matrix-wrapper .matrix-block {
    row-gap: 1rem;
    margin-bottom: 3rem;
    margin-right: 1rem; }
    @media (max-width: 319px) {
      .matrix-wrapper .matrix-block {
        flex-direction: column;
        overflow-x: auto !important;
        overflow: hidden;
        padding-bottom: 0px;
        margin-bottom: 7px; } }

@media (min-width: 320px) and (max-width: 991px) {
  .matrix-wrapper .matrix-block {
    flex-direction: column;
    overflow-x: auto !important;
    overflow: hidden;
    padding-bottom: 0px;
    margin-bottom: 7px; } }
    .matrix-wrapper .matrix-block__matrix-description {
      font-size: 20px; }
    .matrix-wrapper .matrix-block__quest .heading-asterisk p:last-child::after {
      position: relative;
      content: "*";
      color: #f52847;
      font-size: 14px;
      font-weight: 700;
      top: -5px; }
    @media (max-width: 319px) {
      .matrix-wrapper .matrix-block__quest {
        min-width: 100%;
        max-width: 100%;
        height: auto; } }

@media (min-width: 320px) and (max-width: 991px) {
  .matrix-wrapper .matrix-block__quest {
    min-width: 100%;
    max-width: 100%;
    height: auto; } }

@media (min-width: 992px) and (max-width: 1024px) {
  .matrix-wrapper .matrix-block__quest {
    min-width: 100%;
    max-width: 100%; } }
    .matrix-wrapper .matrix-block__quest em {
      background: none !important; }
    .matrix-wrapper .matrix-block__quest h4 {
      font-size: 24px;
      margin: 0;
      margin-bottom: 0rem;
      font-weight: 500;
      display: block; }
      @media (max-width: 319px) {
        .matrix-wrapper .matrix-block__quest h4 {
          font-size: 15px;
          font-weight: 500; } }

@media (min-width: 320px) and (max-width: 767px) {
  .matrix-wrapper .matrix-block__quest h4 {
    font-size: 15px;
    font-weight: 500; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .matrix-wrapper .matrix-block__quest h4 {
    font-size: 22px;
    font-weight: 500; } }
      .matrix-wrapper .matrix-block__quest h4 span {
        flex: 0 0 auto; }
        .matrix-wrapper .matrix-block__quest h4 span:first-child {
          min-width: 30px;
          font-weight: 400 !important; }
        .matrix-wrapper .matrix-block__quest h4 span:last-child {
          max-width: calc(100% - 40px);
          word-wrap: break-word; }
    .matrix-wrapper .matrix-block__area {
      flex: 1 1 auto;
      padding-left: 0px; }
      .matrix-wrapper .matrix-block__area .text-survey {
        width: 100%;
        margin: 0.5rem 0rem; }
        .matrix-wrapper .matrix-block__area .text-survey span {
          flex: 0 1 auto;
          text-align: center;
          font-size: 16px;
          color: #007c89ff;
          font-weight: 400; }
      .matrix-wrapper .matrix-block__area .matrixbox-wrapper {
        width: 100%; }
      .matrix-wrapper .matrix-block__area--list {
        flex: 1 1 auto;
        column-gap: 0.5rem;
        width: 100%; }
        @media (max-width: 319px) {
          .matrix-wrapper .matrix-block__area--list {
            margin: 0px;
            height: auto;
            -webkit-box-align: start;
            -ms-flex-align: start;
            -webkit-align-items: flex-start;
            -moz-align-items: flex-start;
            align-items: flex-start; } }

@media (min-width: 320px) and (max-width: 767px) {
  .matrix-wrapper .matrix-block__area--list {
    height: auto;
    margin: 0px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; } }
        .matrix-wrapper .matrix-block__area--list li {
          flex: 1 1 auto;
          cursor: pointer;
          row-gap: 0.5rem;
          position: relative; }
          .matrix-wrapper .matrix-block__area--list li:first-child {
            flex: 1 1 auto; }
            .matrix-wrapper .matrix-block__area--list li:first-child span {
              text-align: left; }
          .matrix-wrapper .matrix-block__area--list li:last-child {
            flex: 1 1 auto; }
            .matrix-wrapper .matrix-block__area--list li:last-child span {
              text-align: right; }
          .matrix-wrapper .matrix-block__area--list li .box-color {
            flex: 1 1 auto;
            overflow: hidden;
            height: 50px;
            background-color: #dfe1e6;
            -moz-transition: all 0.6s ease-in-out;
            -o-transition: all 0.6s ease-in-out;
            -webkit-transition: all 0.6s ease-in-out;
            transition: all 0.6s ease-in-out; }
            .matrix-wrapper .matrix-block__area--list li .box-color:hover {
              -moz-transition: all 0.4s ease-in-out;
              -o-transition: all 0.4s ease-in-out;
              -webkit-transition: all 0.4s ease-in-out;
              transition: all 0.4s ease-in-out; }
          .matrix-wrapper .matrix-block__area--list li .option-color0 {
            background: #007c8933; }
            .matrix-wrapper .matrix-block__area--list li .option-color0:hover {
              -moz-transform: scale(1.03);
              -o-transform: scale(1.03);
              -ms-transform: scale(1.03);
              -webkit-transform: scale(1.03);
              transform: scale(1.03); }
          .matrix-wrapper .matrix-block__area--list li .option-color1 {
            background: #007c8966; }
            .matrix-wrapper .matrix-block__area--list li .option-color1:hover {
              -moz-transform: scale(1.032);
              -o-transform: scale(1.032);
              -ms-transform: scale(1.032);
              -webkit-transform: scale(1.032);
              transform: scale(1.032); }
          .matrix-wrapper .matrix-block__area--list li .option-color2 {
            background: #007c8999; }
            .matrix-wrapper .matrix-block__area--list li .option-color2:hover {
              -moz-transform: scale(1.034);
              -o-transform: scale(1.034);
              -ms-transform: scale(1.034);
              -webkit-transform: scale(1.034);
              transform: scale(1.034); }
          .matrix-wrapper .matrix-block__area--list li .option-color3 {
            background: #22b0c099; }
            .matrix-wrapper .matrix-block__area--list li .option-color3:hover {
              -moz-transform: scale(1.036);
              -o-transform: scale(1.036);
              -ms-transform: scale(1.036);
              -webkit-transform: scale(1.036);
              transform: scale(1.036); }
          .matrix-wrapper .matrix-block__area--list li .option-color4 {
            background: #15a2b299; }
            .matrix-wrapper .matrix-block__area--list li .option-color4:hover {
              -moz-transform: scale(1.036);
              -o-transform: scale(1.036);
              -ms-transform: scale(1.036);
              -webkit-transform: scale(1.036);
              transform: scale(1.036); }
          .matrix-wrapper .matrix-block__area--list li .option-color4 {
            background: #17b5c6cc; }
            .matrix-wrapper .matrix-block__area--list li .option-color4:hover {
              -moz-transform: scale(1.036);
              -o-transform: scale(1.036);
              -ms-transform: scale(1.036);
              -webkit-transform: scale(1.036);
              transform: scale(1.036); }
          .matrix-wrapper .matrix-block__area--list li .option-color5 {
            background: #12a5b6cc; }
            .matrix-wrapper .matrix-block__area--list li .option-color5:hover {
              -moz-transform: scale(1.036);
              -o-transform: scale(1.036);
              -ms-transform: scale(1.036);
              -webkit-transform: scale(1.036);
              transform: scale(1.036); }
          .matrix-wrapper .matrix-block__area--list li .option-color6 {
            background: #0b9aaacc; }
            .matrix-wrapper .matrix-block__area--list li .option-color6:hover {
              -moz-transform: scale(1.036);
              -o-transform: scale(1.036);
              -ms-transform: scale(1.036);
              -webkit-transform: scale(1.036);
              transform: scale(1.036); }
          .matrix-wrapper .matrix-block__area--list li .option-color7 {
            background: #048e9dcc; }
            .matrix-wrapper .matrix-block__area--list li .option-color7:hover {
              -moz-transform: scale(1.036);
              -o-transform: scale(1.036);
              -ms-transform: scale(1.036);
              -webkit-transform: scale(1.036);
              transform: scale(1.036); }
          .matrix-wrapper .matrix-block__area--list li .option-color8 {
            background: #007c89cc; }
            .matrix-wrapper .matrix-block__area--list li .option-color8:hover {
              -moz-transform: scale(1.036);
              -o-transform: scale(1.036);
              -ms-transform: scale(1.036);
              -webkit-transform: scale(1.036);
              transform: scale(1.036); }
          .matrix-wrapper .matrix-block__area--list li .option-color9 {
            background: #007c89ff; }
            .matrix-wrapper .matrix-block__area--list li .option-color9:hover {
              -moz-transform: scale(1.08);
              -o-transform: scale(1.08);
              -ms-transform: scale(1.08);
              -webkit-transform: scale(1.08);
              transform: scale(1.08); }
          .matrix-wrapper .matrix-block__area--list li span {
            flex: 0 1 auto;
            text-align: center;
            font-size: 14px;
            color: #007c89ff;
            font-weight: 400;
            font-family: "Roboto", sans-serif; }
      .matrix-wrapper .matrix-block__area--activecheck {
        flex: 0 0 auto;
        row-gap: 0.5rem;
        padding-left: 1.5rem; }
        .matrix-wrapper .matrix-block__area--activecheck .active-item {
          cursor: pointer;
          flex: 1 1 auto;
          overflow: hidden;
          height: 50px;
          width: 50px;
          background-color: #dfe1e6; }
        .matrix-wrapper .matrix-block__area--activecheck .answer {
          background-color: #007c89ff; }
        .matrix-wrapper .matrix-block__area--activecheck span {
          flex: 0 1 auto;
          text-align: center;
          font-size: 14px;
          color: #007c89ff; }
