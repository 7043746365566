@import './sass/base/reset';
@import './sass/base/typography';
@import './sass/helpers/controls';
@import './sass/vendors/common';
@import './sass/vendors/override';
@import './sass/vendors/bootstrap';
@import './sass/vendors/antd';
* {
  margin: 0;
  padding: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  @include box-shadow(0 0 0 rgba(255, 255, 255, 0) !important);
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -moz-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  background-color: transparent !important;
}

::selection {
  color: #fff !important;
  background-color: #35807e !important;
}
em {
  background-color: #fcf3c0;
  font-weight: 600;
}
img {
  border: 0;
  outline: 0;
  max-width: 100%;
  display: inline-block;
}
a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
p {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  line-height: 1.428;
  font-size: 14px;
  font-weight: 400;
  font-family: $font-web;
  min-width: auto !important;
  background-color: theme-colors('background', 'white');
  color: theme-colors('text', 'primary');
  &.modal-open {
    overflow: hidden;
  }
}
