$theme-colors-key: "base" !default;
$theme-colors: (
	"primary": (
		"light": #35807e,
		"base": #35807e,
		"dark": #35807e,
	),
	"secondary": (
		"light": #5c6970,
		"base": #3b4449,
		"dark": #272c2f,
		"neutral": #f2f2f3,
		"orange": #f46d43,
		"blue": #4575b4,
		"light-orange": #fff2cc,
		"light-blue": #c9eff3,
		"light-gray": #f2f2f3,
	),
	"barchart": (
		"light": #5c6970,
		"dark": #5c6970,
		"neutral": #69a5a2,
		"orange": #f46d43,
		"blue": #4575b4,
		"gray": #8b989f,
		"green": #3d728b,
		"light-neutral": #fff8f1,
		"light-green": #ecf5f1,
		"light-orange": #fff2cc,
		"light-blue": #c9eff3,
		"light-gray": #f2f2f3,
	),
	"foreground": (
		"base": #7e878f,
		"light": #f5f7f9,
		"dark": #3f3f3f,
		"blue": #4575b4,
		"pink": #f5d1d4,
		"blue-light": #74add1,
		"sky-blue": #abd9e9,
		"sky-bluelight": #c9eff3,
		"gray": #f9f9f6,
		"dark-gray": #29363d,
		"red": #d73027,
		"red-light": #f46d43,
		"orange": #fdae61,
		"orange-light": #fee090,
		"light-pink": #fba58b,
		"light-gray": #8088a1,
		"light-blue": #8577fa,
		"light-orange": #fbb473,
		"light-green": #78c37c,
		"light-sky": #74aefa,
		"light-purple": #a882c5,
	),
	"text": (
		"primary": #29363d,
		"secondary": #5c6970,
		"teritiary": #8f9ca3,
		"link": #4799eb,
		"gray": #707070,
	),
	"accent": (
		"primary": #ed7d31,
		"secondary": #ffc000,
		"orange": #f95d2d,
		"blue": #061fff,
		"green": #16b857,
		"gray": #8c8c8c,
		"dark": #403b3b,
		"yellow": #ffbe00,
	),
	"badge": (
		"good": #c9eff3,
		"neutral": #f2f2f3,
		"bad": #fee090,
	),
	"border": (
		"light": #aaaaaa,
		"primary": #e4e5e7,
	),
	"background": (
		"light-yellow": #fbeeca,
		"light-green": #d8eacc,
		"white": #fff,
		"black": #000,
		"black-light": #5f5d5d,
		"black-lighter": #51504f,
		"yellow": #ffb42e,
		"orange": #ff903c,
		"orange-dark": #f5740b,
		"orange-darken": #ee8031,
		"orange-light": #f56e00,
		"orange-medium-light": #ff903c,
		"orange-logo": #f15d22,
		"red": #f52847,
		"dark-red": #ef5858,
		"green": #4bc758,
		"dark-green": #16ad1c,
		"blue": #0094fa,
		"blue-light": #06455f,
		"tealnew": #007c89,
		"teal": #218894,
		"nav-color": #655d5d,
		"teal-light": #35807e,
		"teal-lighter": #28a29f,
		"teal-darken": #5d5d5d,
		"teal-wave": #2ba29e,
		"teal-dark": #00a6a0,
		"off-white": #fffdfb,
		"grey": #979797,
		"teal-btn": #69b1b9,
		"ash": #6c757d,
		"ash-light": #3d4246,
		"bg-color": #fafafa,
		"light-gray": #eeeeee,
		"gray-light": #cccccc,
	),
);

$theme-opacity: ();
@function theme-colors($name: "primary", $variant: $theme-colors-key, $opacity: 1) {
	$color: null;
	$color-spectrum: map-get($theme-colors, $name);
	@if $color-spectrum {
		$color: map-get($color-spectrum, $variant);
	}
	$alpha: if(type-of($opacity) == "number", $opacity, map-get($theme-opacity, $opacity));
	@if $alpha {
		$color: rgba($color, $alpha);
	}
	@return $color;
}
