/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: font-url("public/fonts/Inter-Thin.woff2") format("woff2"), font-url("public/fonts/Inter-Thin.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: font-url("public/fonts/Inter-ExtraLight.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: font-url("public/fonts/Inter-Light.woff2") format("woff2"), font-url("public/fonts/Inter-Light.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: font-url("public/fonts/Inter-Regular.woff2") format("woff2"), font-url("public/fonts/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: font-url("public/fonts/Inter-Medium.woff2") format("woff2"), font-url("public/fonts/Inter-Medium.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: font-url("public/fonts/Inter-SemiBold.woff2") format("woff2"), font-url("public/fonts/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: font-url("public/fonts/Inter-Bold.woff2") format("woff2"), font-url("public/fonts/Inter-Bold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: font-url("public/fonts/Inter-ExtraBold.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  src: font-url("public/fonts/Inter-Black.woff2") format("woff2"), font-url("public/fonts/Inter-Black.woff") format("woff"); }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-column {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flexbox, .loader-wrapper,
.loader-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.all-center, .loader-wrapper,
.loader-block {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.btn-gradient {
  background-image: -webkit-linear-gradient(legacy-direction(to right), #35807e, #35807e);
  background-image: -webkit-linear-gradient(to right, #35807e, #35807e);
  background: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: linear-gradient(to right, #35807e, #35807e); }

.radius-xs {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem; }

.radius-sm {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem; }

.radius-md {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem; }

.radius-lg {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem; }

.radius-xl {
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem; }

.radius-xxl {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem; }

/* ============= Typographt ==============*/
.heading-one {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #29363d; }

.heading-two {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29363d; }

.heading-three {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #29363d; }

.running-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5c6970; }

.small-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8f9ca3; }

.link-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #4799eb; }

/* ============= Typographt END ==============*/
.loader-wrapper,
.loader-block {
  width: 100%;
  height: calc(100vh - 270px); }

.loader {
  flex: 0 auto;
  position: relative;
  overflow: hidden;
  margin-right: 5px; }
  .loader.lg {
    width: 40px !important;
    height: 40px !important; }
  .loader.md {
    width: 34px !important;
    height: 34px !important; }
  .loader.sm {
    width: 24px !important;
    height: 24px !important; }
  .loader.xs {
    width: 16px !important;
    height: 16px !important; }
  .loader.dark {
    padding: 0; }
    .loader.dark .loader__item {
      padding: 0; }
      .loader.dark .loader__item:before {
        background-color: #484848 !important; }
  .loader.white {
    padding: 0; }
    .loader.white .loader__item {
      padding: 0; }
      .loader.white .loader__item:before {
        background-color: white !important; }
  .loader.blue {
    padding: 0; }
    .loader.blue .loader__item {
      padding: 0; }
      .loader.blue .loader__item:before {
        background-color: #3589c4 !important; }
  .loader__item {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0 !important; }
    .loader__item:before {
      content: '';
      display: block;
      margin: 0 auto;
      overflow: hidden;
      width: 15%;
      height: 15%;
      border-radius: 100%;
      -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%; }
    .loader__item:nth-child(2) {
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg); }
      .loader__item:nth-child(2):before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s; }
    .loader__item:nth-child(3) {
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg); }
      .loader__item:nth-child(3):before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s; }
    .loader__item:nth-child(4) {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
      .loader__item:nth-child(4):before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s; }
    .loader__item:nth-child(5) {
      -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      transform: rotate(120deg); }
      .loader__item:nth-child(5):before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s; }
    .loader__item:nth-child(6) {
      -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
      transform: rotate(150deg); }
      .loader__item:nth-child(6):before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s; }
    .loader__item:nth-child(7) {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
      .loader__item:nth-child(7):before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s; }
    .loader__item:nth-child(8) {
      -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
      transform: rotate(210deg); }
      .loader__item:nth-child(8):before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s; }
    .loader__item:nth-child(9) {
      -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
      transform: rotate(240deg); }
      .loader__item:nth-child(9):before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s; }
    .loader__item:nth-child(10) {
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg); }
      .loader__item:nth-child(10):before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s; }
    .loader__item:nth-child(11) {
      -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
      transform: rotate(300deg); }
      .loader__item:nth-child(11):before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s; }
    .loader__item:nth-child(12) {
      -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
      transform: rotate(330deg); }
      .loader__item:nth-child(12):before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s; }

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }
