/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: font-url("public/fonts/Inter-Thin.woff2") format("woff2"), font-url("public/fonts/Inter-Thin.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: font-url("public/fonts/Inter-ExtraLight.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: font-url("public/fonts/Inter-Light.woff2") format("woff2"), font-url("public/fonts/Inter-Light.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: font-url("public/fonts/Inter-Regular.woff2") format("woff2"), font-url("public/fonts/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: font-url("public/fonts/Inter-Medium.woff2") format("woff2"), font-url("public/fonts/Inter-Medium.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: font-url("public/fonts/Inter-SemiBold.woff2") format("woff2"), font-url("public/fonts/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: font-url("public/fonts/Inter-Bold.woff2") format("woff2"), font-url("public/fonts/Inter-Bold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: font-url("public/fonts/Inter-ExtraBold.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  src: font-url("public/fonts/Inter-Black.woff2") format("woff2"), font-url("public/fonts/Inter-Black.woff") format("woff"); }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-column, .matrixsurvey-table, .mob-matrixsurvey-table, .matrixsurvey-img, .mob-matrixsurvey-img, .singlesurvey-matrixtable {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.all-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.btn-gradient {
  background-image: -webkit-linear-gradient(legacy-direction(to right), #35807e, #35807e);
  background-image: -webkit-linear-gradient(to right, #35807e, #35807e);
  background: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: linear-gradient(to right, #35807e, #35807e); }

.radius-xs {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem; }

.radius-sm {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem; }

.radius-md {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem; }

.radius-lg {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem; }

.radius-xl {
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem; }

.radius-xxl {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem; }

/* ============= Typographt ==============*/
.heading-one {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #29363d; }

.heading-two {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29363d; }

.heading-three {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #29363d; }

.running-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5c6970; }

.small-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8f9ca3; }

.link-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #4799eb; }

/* ============= Typographt END ==============*/
.matrixsurvey-table {
  margin: 1rem 1rem 1rem 0rem;
  overflow-x: auto;
  width: 100%;
  /* position: absolute; */
  /* left: 50px; */
  width: calc(100% - 0px);
  padding: 5px;
  text-align: center; }
  .matrixsurvey-table__scroll {
    max-height: calc(100vh - 380px);
    margin-bottom: 1.5rem; }
  .matrixsurvey-table .metric-row {
    background: #fff; }
    .matrixsurvey-table .metric-row td {
      background: #fff; }
    .matrixsurvey-table .metric-row th {
      background: #fff; }
  .matrixsurvey-table__text {
    width: 130px;
    padding: 1rem 0rem;
    font-size: 18px;
    cursor: pointer;
    color: #006D7CFF; }
  .matrixsurvey-table__head {
    min-height: 70px;
    max-height: 200px;
    line-height: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: #fff; }
    .matrixsurvey-table__head textarea {
      text-align: center; }
    .matrixsurvey-table__head--label {
      height: 50px; }
    .matrixsurvey-table__head td {
      background: #fff; }
    .matrixsurvey-table__head th {
      background: #fff; }
  .matrixsurvey-table textarea {
    border: none; }
  .matrixsurvey-table table {
    margin: 0rem;
    padding: 1rem 0rem;
    border-collapse: separate;
    border-spacing: 0px 8px;
    border-radius: 8px; }
    .matrixsurvey-table table tr {
      padding: 1rem;
      border-radius: 10px; }
      .matrixsurvey-table table tr td {
        background: #eef0f0;
        min-height: 85px; }
      .matrixsurvey-table table tr th {
        background: #eef0f0; }
    .matrixsurvey-table table .metrix-th {
      vertical-align: middle;
      width: 120px;
      height: 70px; }
    .matrixsurvey-table table .fixed {
      padding: 10px 16px 10px 24px;
      background: #eef0f0;
      position: sticky;
      left: 0px;
      z-index: 1;
      vertical-align: middle;
      text-align: left;
      font-weight: inherit;
      overflow-wrap: break-word;
      max-width: 360px;
      min-width: 300px;
      border-bottom-left-radius: var(--sampler-comp-radius-matrix);
      border-top-left-radius: var(--sampler-comp-radius-matrix);
      height: 48px;
      font-size: 16px;
      line-height: 29px; }
      .matrixsurvey-table table .fixed input {
        min-width: 300px;
        display: inline;
        word-wrap: break-word; }

@media (min-width: 310px) and (max-width: 819px) {
  .matrixsurvey-table table .fixed {
    max-width: 250px;
    min-width: 250px;
    position: static;
    left: 0px;
    z-index: 1; } }

.mob-matrixsurvey-table {
  margin: 1rem 1rem 1rem 0rem;
  overflow-x: auto;
  width: 375px;
  /* position: absolute; */
  /* left: 50px; */
  padding: 5px;
  text-align: center; }
  .mob-matrixsurvey-table__scroll {
    max-height: calc(100vh - 380px);
    margin-bottom: 1.5rem; }
  .mob-matrixsurvey-table .metric-row {
    background: #fff; }
    .mob-matrixsurvey-table .metric-row td {
      background: #fff; }
    .mob-matrixsurvey-table .metric-row th {
      background: #fff; }
  .mob-matrixsurvey-table__text {
    width: 130px;
    padding: 1rem 0rem;
    font-size: 18px;
    cursor: pointer;
    color: #006D7CFF; }
  .mob-matrixsurvey-table__head {
    min-height: 70px;
    max-height: 200px;
    padding-top: 1rem;
    text-align: center;
    background: #fff; }
    .mob-matrixsurvey-table__head textarea {
      text-align: center; }
    .mob-matrixsurvey-table__head--label {
      height: 50px; }
    .mob-matrixsurvey-table__head td {
      background: #fff; }
    .mob-matrixsurvey-table__head th {
      background: #fff; }
  .mob-matrixsurvey-table textarea {
    border: none; }
  .mob-matrixsurvey-table table {
    margin: 0rem;
    padding: 1rem 0rem;
    border-collapse: separate;
    border-spacing: 0px 8px;
    border-radius: 8px; }
    .mob-matrixsurvey-table table tr {
      padding: 1rem;
      border-radius: 10px; }
      .mob-matrixsurvey-table table tr td {
        background: #eef0f0; }
      .mob-matrixsurvey-table table tr th {
        background: #eef0f0; }
    .mob-matrixsurvey-table table .metrix-th {
      vertical-align: middle;
      width: 120px; }
    .mob-matrixsurvey-table table .fixed {
      padding: 10px 16px 10px 24px;
      background: #eef0f0;
      position: sticky;
      left: 0px;
      z-index: 1;
      vertical-align: middle;
      text-align: left;
      font-weight: inherit;
      overflow-wrap: break-word;
      max-width: 270px;
      min-width: 270px;
      border-bottom-left-radius: var(--sampler-comp-radius-matrix);
      border-top-left-radius: var(--sampler-comp-radius-matrix);
      height: 48px; }
      .mob-matrixsurvey-table table .fixed input {
        min-width: 300px;
        display: inline;
        word-wrap: break-word; }

@media (min-width: 310px) and (max-width: 819px) {
  .mob-matrixsurvey-table table .fixed {
    max-width: 250px;
    min-width: 250px;
    position: static;
    left: 0px;
    z-index: 1; } }

#wrapper {
  height: 200px;
  width: 400px;
  background: white;
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px -7px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: calc(50% - 100px);
  left: 50%;
  transform: translateX(-200px); }

#wrapper p {
  color: rgba(0, 0, 0, 0.65);
  font-size: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 15px 20px;
  margin: 0;
  margin-bottom: 18px; }

/* label container */
.radio-button-container {
  color: rgba(0, 0, 0, 0.75);
  display: block;
  position: relative;
  padding-left: 45px;
  line-height: 25px;
  margin-bottom: 12px;
  margin-left: 30px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default radio button */
.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

/* Create a custom radio button */
.checkmark {
  height: 1.5rem;
  width: 1.5rem;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transform: translateY(-2px); }

/* On mouse-over, add a grey background color */
.radio-button-container:hover input ~ .checkmark {
  border-color: rgba(0, 0, 0, 0.5); }

/* When the radio button is checked */
.radio-button-container input:checked ~ .checkmark {
  background-color: rgba(0, 0, 0, 0);
  border-color: teal; }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the indicator (dot/circle) when checked */
.radio-button-container input:checked ~ .checkmark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.radio-button-container .checkmark:after {
  top: 50%;
  left: 50%;
  transition: opacity 0.1s;
  transform: translate(-50%, -50%);
  width: 13.9px;
  height: 13.9px;
  border-radius: 50%;
  background: teal; }

.matrixsurvey-img {
  margin: 1rem 1rem 1rem 0rem;
  overflow-x: auto;
  width: 100%; }

@media (min-width: 1860px) and (max-width: 12559px) {
  .matrixsurvey-img {
    width: 880px; } }

@media (min-width: 1670px) and (max-width: 1858px) {
  .matrixsurvey-img {
    width: 780px; } }

@media (min-width: 1467px) and (max-width: 1668px) {
  .matrixsurvey-img {
    width: 630px; } }

@media (min-width: 1368px) and (max-width: 1467px) {
  .matrixsurvey-img {
    width: 560px; } }

@media (min-width: 1268px) and (max-width: 1367px) {
  .matrixsurvey-img {
    width: 515px; } }

@media (min-width: 1198px) and (max-width: 1266px) {
  .matrixsurvey-img {
    width: 460px; } }

@media (min-width: 1048px) and (max-width: 1197px) {
  .matrixsurvey-img {
    width: 410px; } }

@media (min-width: 993px) and (max-width: 1046px) {
  .matrixsurvey-img {
    width: 400px; } }

@media (min-width: 860px) and (max-width: 991px) {
  .matrixsurvey-img {
    width: 800px; } }

@media (min-width: 768px) and (max-width: 858px) {
  .matrixsurvey-img {
    width: 760px; } }

@media (min-width: 668px) and (max-width: 766px) {
  .matrixsurvey-img {
    width: 525px; } }

@media (min-width: 588px) and (max-width: 666px) {
  .matrixsurvey-img {
    width: 560px; } }

@media (min-width: 488px) and (max-width: 586px) {
  .matrixsurvey-img {
    width: 380px; } }

@media (min-width: 387px) and (max-width: 486px) {
  .matrixsurvey-img {
    width: 360px; } }

@media (min-width: 335px) and (max-width: 385px) {
  .matrixsurvey-img {
    width: 300px; } }

@media (min-width: 315px) and (max-width: 333px) {
  .matrixsurvey-img {
    width: 280px; } }
  .matrixsurvey-img .metric-row {
    background: #fff; }
    .matrixsurvey-img .metric-row td {
      background: #fff; }
    .matrixsurvey-img .metric-row th {
      background: #fff; }
  .matrixsurvey-img__text {
    width: 130px;
    padding: 1rem 0rem;
    font-size: 18px;
    cursor: pointer;
    color: #006D7CFF; }
  .matrixsurvey-img__head {
    min-width: 100px;
    max-width: 150px;
    min-height: 70px;
    max-height: 200px;
    padding-top: 1rem;
    background: #fff; }
    .matrixsurvey-img__head textarea {
      text-align: center; }
    .matrixsurvey-img__head--label {
      height: 50px; }
    .matrixsurvey-img__head td {
      background: #fff; }
    .matrixsurvey-img__head th {
      background: #fff; }
  .matrixsurvey-img textarea {
    border: none; }
  .matrixsurvey-img table {
    margin: 0rem;
    padding: 1rem 0rem;
    border-collapse: separate;
    border-spacing: 0px 8px;
    border-radius: 8px; }
    .matrixsurvey-img table tr {
      padding: 1rem;
      border-radius: 10px; }
      .matrixsurvey-img table tr td {
        background: #eef0f0; }
      .matrixsurvey-img table tr th {
        background: #eef0f0; }
    .matrixsurvey-img table .metrix-th {
      vertical-align: middle;
      width: 120px; }
    .matrixsurvey-img table .fixed {
      padding: 10px 16px 10px 24px;
      background: #eef0f0;
      position: sticky;
      left: 0px;
      z-index: 1;
      vertical-align: middle;
      text-align: left;
      font-weight: inherit;
      overflow-wrap: break-word;
      max-width: 360px;
      min-width: 300px;
      border-bottom-left-radius: var(--sampler-comp-radius-matrix);
      border-top-left-radius: var(--sampler-comp-radius-matrix);
      height: 48px; }

@media (min-width: 320px) and (max-width: 1235px) {
  .matrixsurvey-img table .fixed {
    max-width: 280px;
    min-width: 280px; } }
      .matrixsurvey-img table .fixed input {
        min-width: 300px;
        display: inline;
        word-wrap: break-word; }

.metrix-new-th {
  display: flex;
  align-items: center;
  width: 135px;
  height: 50px;
  justify-content: center;
  vertical-align: middle; }
  .metrix-new-th .radio-button-container {
    padding-left: 0px;
    margin-left: -20px;
    justify-content: center;
    display: flex;
    margin-bottom: 0px !important; }
    .metrix-new-th .radio-button-container input {
      position: unset !important; }

.surveymetrix-th {
  display: flex;
  align-items: center;
  width: 75px;
  height: 50px;
  justify-content: center;
  vertical-align: middle; }
  .surveymetrix-th .radio-button-container {
    padding-left: 0px;
    margin-left: -3px;
    justify-content: center;
    display: flex;
    margin-bottom: 0px !important; }
    .surveymetrix-th .radio-button-container input {
      position: unset !important; }

.mob-matrixsurvey-img {
  margin: 1rem 1rem 1rem 0rem;
  overflow-x: auto;
  width: 375px; }
  .mob-matrixsurvey-img .metric-row {
    background: #fff; }
    .mob-matrixsurvey-img .metric-row td {
      background: #fff; }
    .mob-matrixsurvey-img .metric-row th {
      background: #fff; }
  .mob-matrixsurvey-img__text {
    width: 130px;
    padding: 1rem 0rem;
    font-size: 18px;
    cursor: pointer;
    color: #006D7CFF; }
  .mob-matrixsurvey-img__head {
    min-width: 100px;
    max-width: 150px;
    min-height: 70px;
    max-height: 200px;
    padding-top: 1rem;
    background: #fff; }
    .mob-matrixsurvey-img__head textarea {
      text-align: center; }
    .mob-matrixsurvey-img__head--label {
      height: 50px; }
    .mob-matrixsurvey-img__head td {
      background: #fff; }
    .mob-matrixsurvey-img__head th {
      background: #fff; }
  .mob-matrixsurvey-img textarea {
    border: none; }
  .mob-matrixsurvey-img table {
    margin: 0rem;
    padding: 1rem 0rem;
    border-collapse: separate;
    border-spacing: 0px 8px;
    border-radius: 8px; }
    .mob-matrixsurvey-img table tr {
      padding: 1rem;
      border-radius: 10px; }
      .mob-matrixsurvey-img table tr td {
        background: #eef0f0; }
      .mob-matrixsurvey-img table tr th {
        background: #eef0f0; }
    .mob-matrixsurvey-img table .metrix-th {
      vertical-align: middle;
      width: 120px; }
    .mob-matrixsurvey-img table .fixed {
      padding: 10px 16px 10px 24px;
      background: #eef0f0;
      position: sticky;
      left: 0px;
      z-index: 1;
      vertical-align: middle;
      text-align: left;
      font-weight: inherit;
      overflow-wrap: break-word;
      max-width: 270px;
      min-width: 270px;
      border-bottom-left-radius: var(--sampler-comp-radius-matrix);
      border-top-left-radius: var(--sampler-comp-radius-matrix);
      height: 48px; }

@media (min-width: 320px) and (max-width: 1235px) {
  .mob-matrixsurvey-img table .fixed {
    max-width: 280px;
    min-width: 280px; } }
      .mob-matrixsurvey-img table .fixed input {
        min-width: 300px;
        display: inline;
        word-wrap: break-word; }

.metrix-last {
  background: #e1e1e1 !important;
  border-left: 6px solid #fff; }

.singlesurvey-matrixtable {
  margin: 1rem 1rem 1rem 0rem;
  overflow-x: auto;
  width: 100%;
  /* position: absolute; */
  /* left: 50px; */
  width: calc(100% - 0px);
  padding: 5px;
  text-align: center; }
  .singlesurvey-matrixtable__scroll {
    max-height: calc(100vh - 380px);
    margin-bottom: 1.5rem; }
  .singlesurvey-matrixtable .metric-row {
    background: #fff; }
    .singlesurvey-matrixtable .metric-row td {
      background: #fff; }
    .singlesurvey-matrixtable .metric-row th {
      background: #fff; }
  .singlesurvey-matrixtable__text {
    width: 130px;
    padding: 1rem 0rem;
    font-size: 18px;
    cursor: pointer;
    color: #006D7CFF; }
  .singlesurvey-matrixtable__head {
    min-width: 60px;
    max-width: 75px;
    margin: 0rem 0.8rem;
    min-height: 70px;
    max-height: 200px;
    line-height: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: #fff; }
    .singlesurvey-matrixtable__head textarea {
      text-align: center; }
    .singlesurvey-matrixtable__head--label {
      height: 50px; }
    .singlesurvey-matrixtable__head td {
      background: #fff; }
    .singlesurvey-matrixtable__head th {
      background: #fff; }
  .singlesurvey-matrixtable textarea {
    border: none; }
  .singlesurvey-matrixtable table {
    margin: 0rem;
    padding: 1rem 0rem;
    border-collapse: separate;
    border-spacing: 0px 8px;
    border-radius: 8px; }
    .singlesurvey-matrixtable table tr {
      padding: 1rem;
      border-radius: 10px; }
      .singlesurvey-matrixtable table tr td {
        background: #eef0f0;
        min-height: 85px; }
      .singlesurvey-matrixtable table tr th {
        background: #eef0f0; }
    .singlesurvey-matrixtable table .singlemetrix-th {
      vertical-align: middle;
      width: 80px;
      height: auto;
      align-items: center;
      justify-content: center;
      min-height: 80px; }
    .singlesurvey-matrixtable table .fixed {
      padding: 10px 16px 10px 24px;
      background: #eef0f0;
      position: sticky;
      left: 0px;
      z-index: 1;
      vertical-align: middle;
      text-align: left;
      font-weight: inherit;
      overflow-wrap: break-word;
      max-width: 360px;
      min-width: 300px;
      border-bottom-left-radius: var(--sampler-comp-radius-matrix);
      border-top-left-radius: var(--sampler-comp-radius-matrix);
      height: 48px;
      font-size: 16px;
      line-height: 29px; }
      .singlesurvey-matrixtable table .fixed input {
        min-width: 300px;
        display: inline;
        word-wrap: break-word; }

@media (min-width: 310px) and (max-width: 819px) {
  .singlesurvey-matrixtable table .fixed {
    max-width: 250px;
    min-width: 250px;
    position: static;
    left: 0px;
    z-index: 1; } }

.heading-asterisk p:last-child::after {
  position: relative;
  content: "*";
  color: #f52847;
  font-size: 14px;
  font-weight: 700;
  top: -5px; }

.stickyHeader {
  display: flex;
  position: fixed !important;
  top: 0 !important;
  z-index: 2 !important; }
