.node {}

.link {
    stroke: #999;
    stroke-opacity: .6;
}

/* svg {
    border-style: dashed
} */

.d3-tip {
    line-height: 1;
    padding: 6px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
}


/* Creates a small triangle extender for the tooltip */

.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;
}


/* Style northward tooltips specifically */

.d3-tip.n:after {
    margin: -2px 0 0 0;
    top: 100%;
    left: 0;
}