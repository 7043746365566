.amcharts-amexport-menu {
	display: none !important;
}
.amcharts-amexport-menu-level-0 {
	z-index: 0 !important;
	top: -40px !important;

	.amcharts-amexport-item-blank {
		margin: 0;
		z-index: 0 !important;
		width: auto !important;
		height: auto !important;
		min-height: auto !important;
		opacity: 1 !important;
		background-color: #fff !important;

		a {
			display: block;
			overflow: hidden;
			width: 36px !important;
			height: 36px !important;
			cursor: pointer !important;
			background-color: theme-colors("background", "white");
			background-image: url(/img/chart-icon.png);
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: 20px;
			@include border-radius(50%);

			&:hover {
				background-color: #eeebeb !important;
				background-image: url(/img/chart-icon.png);
				@include transition(all 0.4s ease-in-out);
			}
		}
		.amcharts-amexport-menu-level-1,
		.amcharts-amexport-menu-level-2 {
			border: #e5e5e5 solid 1px;

			li {
				margin: 0 !important;
				width: auto !important;
				height: auto !important;
				padding: 0px !important;
				background-color: #fff;
				border-bottom: #e5e5e5 solid 1px;
				a {
					width: auto !important;
					height: auto !important;
					margin: 0 !important;
					border: 0 !important;
					color: #333333;
					cursor: pointer !important;
					padding: 6px 11px !important;
					background-color: #fff !important;
					background-image: none !important;
					@include border-radius(0);
					@include box-shadow(0 0 0 rgba(0, 0, 0, 0));

					&:hover {
						background-color: #737272 !important;
						color: #fff;
					}
				}

				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}
}
.css-2b097c-container {
	@extend .flex-column;
	.css-yk16xz-control,
	.css-1pahdxg-control {
		height: 50px;
		min-height: 50px;
		border: 0px;
		background-color: #ffffff;
		@include box-shadow(0 4px 4px rgba(0, 0, 0, 0));
		@include border-radius(0);
		.css-1uccc91-singleValue {
			height: 30px;
			line-height: 30px;
		}
		&:hover {
			border-color: #979797;
		}
	}
	.css-26l3qy-menu {
		.css-4ljt47-MenuList,
		.css-11unzgr {
			.css-9gakcf-option {
				background-color: transparent;
				color: rgba(0, 0, 0, 0.65);
				font-weight: 600;
			}
			.css-1n7v3ny-option {
				background-color: #e6f7ff;
			}
			& > div:hover {
				background-color: #e6f7ff;
			}
		}
	}

	.css-9gakcf-option {
		background-color: transparent;
		color: rgba(0, 0, 0, 0.65);
		font-weight: 600;
	}
	.css-1n7v3ny-option {
		background-color: #e6f7ff;
	}
	.css-26l3qy-menu {
		z-index: 9;
		div {
			div:hover {
				background-color: #e6f7ff;
			}
		}
	}
}
.disable-text {
	color: #666666 !important;
}
.status-text{
	color: red;
	font-size: 16px;
}
.people-table-cell{
	font-size: 16px;
	color: #5c6970;
}
.read-more-link {
	color: theme-colors("text", "primary", 0.3);
	padding-left: 5px;
	a {
		color: theme-colors("text", "primary", 0.3);
		text-decoration: underline;

		&:hover {
			color: theme-colors("background", "orange-medium-light") !important;
		}
	}
}
.goals {
	@extend .flexbox;
	label {
		width: auto !important;
		flex-direction: row;
		background: theme-colors("background", "white", 0.6);
		border: 2px solid theme-colors("background", "white");
		line-height: 18px !important;

		border-radius: 5px;
		display: flex;
		font-size: 12px;
		font-weight: 500;

		.ant-radio {
			vertical-align: middle;
			position: inherit;
			align-items: center;
			justify-content: center;
			display: flex;
			margin-right: 2px;
			margin-top: -3px;
			.ant-radio-inner {
				background: #e5e5e5;

				border: 2px solid #ffffff;
				border-radius: 100%;
				box-shadow: none !important;
				width: 24px;
				height: 24px;
				&:after {
					background: #497e7d;
					border-radius: 100%;
					top: 0px;
					left: 0px;
					width: 19px;
					height: 19px;
					box-shadow: none !important;
				}
			}
		}
		.ant-radio-checked {
			&:after {
				border: none !important;
			}
		}
	}
}
/* ============= React Multy Carousel ============*/
.react-multi-carousel-list {
	margin: 0;
	.react-multiple-carousel__arrow {
		background-color: transparent;
		&--left,
		&--right {
			text-indent: -999px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 50%;
			opacity: 0.5;
			@include transition(all 0.4s ease-in-out);
			&:hover {
				opacity: 1;
				@include transition(all 0.4s ease-in-out);
			}
		}
		&--left {
			background-image: url(/img/back.svg);
		}
		&--right {
			background-image: url(/img/next.svg);
		}
	}
}
.distributions-action {
	&__button {
		.ant-btn {
			@include media("<=phone") {
				font-size: 13px;
			}
			@include media(">=phone", "<992px") {
				font-size: 13px;
			}
		}
	}
}
