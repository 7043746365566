.main-wrapper {
  min-height: 100vh;
  @extend .flex-column;
  &__scroll-bar {
    max-height: 100vh;
  }
}
.clb-model-form {
  margin-bottom: 5px;
  @extend .flex-column;

  label {
    @extend .running-text;
  }
  li {
    flex: 1 1 auto;
    margin-bottom: 10px;
    @extend .flexbox;
    @extend .item-center;
    .split-block {
      flex: 1 1 auto;
      @extend .flex-column;
      &:not(:last-child) {
        margin-right: 0.863rem;
      }
    }
  }
}
.popover-class {
  min-width: 285px;
  width: 285px;
  @include media(">=phone", "<tablet") {
    min-width: 230px !important;
    width: 230px !important;
  }

  @include media("<phone") {
    min-width: 230px !important;
    width: 230px !important;
  }
}
.comments-list {
  @extend .flex-column;
  li {
    flex: 1 1 auto;
    padding: 1rem 0;
    @extend .running-text;
    @include transition(all 0.4s ease-in-out);
    &:not(:last-child) {
      border-bottom: solid 1px theme-colors("secondary", "light", 0.2);
    }
    &:hover {
      background-color: theme-colors("secondary", "light", 0.05);
      @include transition(all 0.4s ease-in-out);
    }
  }
}
.back-arrow {
  flex: 0 0 auto;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  @extend .flexbox;
  @extend .all-center;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: theme-colors("secondary", "light", 0.8);
    }
  }
}
/*-----jaggu above----*/
.global-h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29.05px;
  color: theme-colors("text", "primary");
}
.global-h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24.2px;
  color: theme-colors("text", "primary");
}
.global-h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20.57px;
  color: theme-colors("text", "primary");
}
.global-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: theme-colors("text", "secondary");
}
.secondary-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: theme-colors("text", "teritiary");
}
.link-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: theme-colors("text", "link");
}
.checkbox,
.radio {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"],
  input[type="radio"] {
    border: 0;
    opacity: 0;
    height: 20px;
    width: 20px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    cursor: pointer;

    &:checked ~ label {
      &:before {
        background-color: theme-colors("secondary", "light", 0.1);
      }

      &:after {
        display: block;
      }
    }
  }

  label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    font-size: 13px;
    height: 20px;
    line-height: 21px;
    margin-bottom: 0px;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: solid 1px theme-colors("secondary", "light", 0.1);
    }

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.checkbox {
  margin: 0;
  label {
    margin: 0;

    &:after {
      left: 7px;
      top: 1px;
      width: 7px;
      height: 13px;
      border: solid theme-colors("background", "white");
      border-width: 0 2px 2px 0;
      @include transform(rotate(45deg));
    }
  }
}
.radio {
  margin: 0;

  label {
    margin: 0;

    &:before {
      @include border-radius(50%);
    }
    &:after {
      left: 6px;
      top: 6px;
      width: 8px;
      height: 8px;
      background-color: theme-colors("background", "white");
      @include transform(rotate(45deg));
      @include border-radius(50%);
    }
  }
}
.add-block {
  flex: 1 1 auto;
  @extend .flexbox;
  @extend .all-center;
}
.admin-content {
  flex: 1 1 auto;
  @extend .flex-column;
  .role-card {
    @extend .flex-column;
    .create-role {
      display: flex;
      justify-content: flex-end;
      background-color: white;
      margin-bottom: 15px;
      padding-top: 0px;
    }
  }
}

.error-message {
  color: theme-colors("background", "red");
  font-size: 12px;
}
.grid-block {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  grid-gap: 10px;
  grid-template-rows: auto;
  align-content: start;
  grid-auto-flow: dense;
  justify-items: stretch;
  padding: 10px;
  justify-content: center;
  &.single-organization {
    grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  }
  li {
    margin: 0;
  }
}
.recharts-responsive-container {
  width: 100% !important;
  height: 100% !important;
  @extend .flexbox;
  @extend .all-center;
  .recharts-wrapper {
    flex: 1 1 auto;
  }
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  padding: 10px;
  gap: 20px;
  justify-content: center;
  @include media(">=phone", "<tablet") {
    grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  }
}
.upload-file {
  @extend .flexbox;
  @extend .all-center;
  //border: 1px dashed grey;
  margin-top: 10px;
  width: 400px;
  height: 250px;
  padding: 5px 5px;
  font-size: 11px;
  @include media("<tablet") {
    width: 300px;
  }
  .close-icon {
    height: 10px;
    width: 10px;
    margin-left: 10px;
    @extend .flexbox;
    @extend .all-center;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: theme-colors("background", "red");
        opacity: 0.6;
      }
    }
    &:hover,
    &:focus {
      svg {
        path {
          opacity: 1;
        }
      }
    }
  }
}
.story-graph {
  width: 100%;
  overflow-y: auto;
}
.login-btn {
  width: 100%;
  button {
    width: 100%;
  }
}
.vertical-list {
  @extend .flex-column;
  li {
    flex: 1 1 auto;
    @extend .flex-column;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    label {
      @extend .flexbox;
      @extend .item-center;
      span {
        flex: 0 0 auto;
        font-size: 14px;
        &.required {
          margin-left: 4px;
          color: theme-colors("background", "red");
        }
      }
    }
    .split-block {
      flex: 1 1 auto;
      @extend .flexbox;
      @extend .item-center;
      .split-half {
        flex: 1 1 50%;
        max-width: 50%;
        @extend .flex-column;
        margin-right: 1.5rem;
      }
    }
    .split-big {
      flex: 1 1 auto;
      @extend .flex-column;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
    .split-small {
      flex: 0 0 auto;
      @extend .flex-column;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
.compare-wrapper {
  margin: 0;
  //padding: 1.5rem 0rem 2.4rem;
  @extend .flexbox;
  @include media("<=phone") {
    width: 100%;
    padding-left: 0px;
    @include flex-direction(column);
  }

  @include media("<=568px", "<992px") {
    width: 100%;
    padding-left: 0px;
    @include flex-direction(column);
  }
  @extend .item-center;
  .explore-longestcomment {
    cursor: pointer;
  }
  li {
    flex: 1 1 auto;
    width: 33%;
    @include media("<=phone") {
      padding-bottom: 20px;
      width: 100%;
    }
    @include media("<=568px", "<992px") {
      padding-bottom: 20px;
      width: 100%;
    }
    @include media(">=568px", "<992px") {
      padding-bottom: 20px;
    }
    @include media(">=992px", "<tablet") {
      margin: 0;
    }
    &:not(:last-child) {
      margin-right: 1rem;
      @include media("<phone") {
        margin-right: 0rem;
      }
      @include media(">=phone", "<tablet") {
        margin-right: 0rem;
      }
    }
    .compare-count {
      @extend .flexbox;
      @extend .item-end;
      h4 {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: -3px;
        margin-right: 5px;
      }
      span {
        @extend .small-text;
      }
    }
    p {
      @extend .small-text;
      padding-top: 21px;
    }
    .recharts-responsive-container {
      margin-left: -63px !important;
    }
    .mini-response-chart {
      @extend .flex-column;

      .no-response-bar {
        @extend .flexbox;
        padding-top: 1rem;
        &__line {
          flex: 0 0 auto;
          min-width: 120px;
          border-bottom: solid 1px theme-colors("background", "black", 0.3);
        }
      }
    }
  }
}
.show-more,
.show-less {
  color: theme-colors("text", "secondary", 0.6);
  padding: 0.8rem 0 2.5rem 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  @extend .flexbox;
  @extend .item-center;
  @include transition(all 0.4s ease-in-out);
  &:hover,
  &:focus {
    color: theme-colors("background", "orange");
    @include transition(all 0.4s ease-in-out);
  }
  svg {
    width: 10px;
    height: 10px;
    fill: theme-colors("text", "secondary", 0.6);
  }
  span {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    @extend .flexbox;
    @extend .item-center;
    svg {
      width: 10px;
      height: 10px;
      fill: theme-colors("text", "secondary", 0.6);
    }
  }
}
/* ==================DATA SERVICE PAGE ONLY ============== */
.descrptive-question {
  @extend .flex-column;
  all: initial;
  all: inherit;
  all: unset;
  line-height: 30px;
  font-size: 20px;
  font-weight: 300;
  p {
    font-size: 16px;
    padding-bottom: 1.1rem;
  }
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 20px !important;
    font-weight: 500 !important ;
  }
  img {
    max-width: 70%;
    margin: 1rem 0 !important;
  }
  ins {
    all: unset !important;
  }
  em {
    //font-style: italic !important;
    //font-weight: normal !important;
    background-color: transparent !important;
    margin-bottom: 1rem;
  }
  code {
    all: unset;
  }
  ul,
  ol,
  li {
    all: unset !important;
    all: unset !important;
    margin: auto !important;
  }
  ul {
    list-style-type: disc !important;
    li {
      display: list-item !important;
      margin-left: 20px !important;
    }
  }

  ol {
    list-style-type: decimal !important;
    li {
      display: list-item !important;
      margin-left: 20px !important;
    }
  }
}
/* ==================DATA SERVICE PAGE ONLY END ============== */
.beta-tag {
  font-style: normal;
  font-size: 11px;
  font-weight: 500;
  margin: 6px;
  background: #44817e;
  padding: 2px 10px 1px 10px;
  color: theme-colors("background", "white");
  border-radius: 25px;
}
.beta-heatmap {
  min-height: 15px;
  font-style: normal;
  font-size: 11px;
  font-weight: 300;
  margin: 6px;
  background: theme-colors("background", "teal-light");
  color: theme-colors("background", "white");
  border-radius: 5px;
  padding: 0px 5px;
}
.add-demographics {
  @extend .flexbox;
  @extend .item-center;
  @extend .running-text;
  padding-left: 0px;
  p {
    margin-left: 10px;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: theme-colors("background", "orange");
    }
  }
}
.nlp-sidebar {
  flex: 0 0 auto;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  align-self: flex-start;
  height: auto;
  @extend .flex-column;
}
.nlp-maincontent {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
  .stack-chart {
    margin-top: 20px;
    overflow-y: auto;

    h3 {
      @extend .heading-three;
    }

    .demographic-selector {
      width: auto;
      margin: 10px 20px;
      label {
        font-size: 16px;
      }
    }
  }
}
.custom-table {
  width: 100%;
  // overflow-x: auto;
  table {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.ql-editor-bubble {
  min-height: 40px;
  min-width: 100%;
  padding: 0.25rem 1rem;
  border: 1px solid theme-colors("background", "black", 0.2);
  @extend .radius-sm;
  @extend .flex-column;
  @extend .justify-center;
  .ql-container {
    @extend .flex-column;
    .ql-editor {
      min-height: 35px;
      max-height: auto;
      padding: 0;
      @extend .flex-column;
      @extend .justify-center;
      p {
        margin: 0 !important;
      }
    }
  }
  .ql-tooltip {
    z-index: 9;
  }
  .ql-toolbar {
    min-height: 30px;
    svg {
      min-height: 17px !important;
      max-height: 17px !important;
      min-width: 17px !important;
      max-width: 17px !important;
    }
    .ql-bold,
    .ql-underline,
    .ql-italic {
      padding-left: 13px;
      padding-top: 6px;
    }
  }
  .ql-blank {
    overflow-x: hidden;
  }
}
.ql-size-huge {
  font-size: 30px;
  font-weight: bolder;
}
.ql-size-large {
  @extend .heading-one;
  font-size: 26px;
}
.ql-size-small {
  @extend .running-text;
  font-size: 24px;
}
h1,
h2,
li,
ol {
  .ql-size-huge {
    font-size: 30px !important;
    font-weight: bolder;
  }
  .ql-size-large {
    @extend .heading-one;
    font-size: 26px !important;
  }
  .ql-size-small {
    @extend .running-text;
    font-size: 12px !important;
  }
}

.ql-editor {
  h1,
  h2,
  li,
  ol {
    .ql-size-huge {
      font-size: 30px !important;
      font-weight: bolder;
    }
    .ql-size-large {
      @extend .heading-one;
      font-size: 26px !important;
    }
    .ql-size-small {
      @extend .running-text;
      font-size: 12px !important;
    }
  }
  li:has(span.ql-size-huge) {
    font-size: 30px;
  }
  li:has(span.ql-size-small) {
    font-size: 12px;
  }

  li:has(span.ql-size-large) {
    font-size: 26px;
  }
}
li:has(span.ql-size-huge) {
  font-size: 30px;
}
li:has(span.ql-size-small) {
  font-size: 12px;
}

li:has(span.ql-size-large) {
  font-size: 26px;
}

li:has(em.ql-size-large) {
  font-size: 26px;
}

li:has(em.ql-size-huge) {
  font-size: 30px;
}
li:has(em.ql-size-small) {
  font-size: 12px;
}

li:has(strong.ql-size-large) {
  font-size: 26px;
}

li:has(strong.ql-size-huge) {
  font-size: 30px;
}
li:has(strong.ql-size-small) {
  font-size: 12px;
}

li:has(u.ql-size-large) {
  font-size: 26px;
}

li:has(u.ql-size-huge) {
  font-size: 30px;
}
li:has(u.ql-size-small) {
  font-size: 12px;
}

.icon-close{
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  background:theme-colors("primary", "base",.7, );
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  margin-left: 0.7rem;
  cursor: pointer;
}
.search-select{
  svg{
    width: 10px;
    margin-left: .5rem;
    fill: #fff;
  }
}