@import "./mixins";
.inline-flex {
  @include inline-flex;
}
.flex-column {
  @include flexbox();
  @include flex-direction(column);
}

.flexbox {
  @include flexbox();
  @include flex-direction(row);
}
.all-center {
  @include justify-content(center);
  @include align-items(center);
}

.justify-center {
  @include justify-content(center);
}

.justify-between {
  @include justify-content(space-between);
}

.justify-around {
  @include justify-content(space-around);
}

.justify-start {
  @include justify-content(flex-start);
}

.justify-end {
  @include justify-content(flex-end);
}

.item-center {
  @include align-items(center);
}

.item-start {
  @include align-items(flex-start);
}

.item-end {
  @include align-items(flex-end);
}

.item-stretch {
  @include align-items(stretch);
}
.item-nowrap {
  @include flex-wrap(nowrap);
}
.item-wrap {
  @include flex-wrap(wrap);
}
.btn-gradient {
  @include linear-gradient(to right, (theme-colors("primary", "base"), theme-colors("primary", "light")));
}
.radius-xs {
  @include border-radius(0.125rem);
}
.radius-sm {
  @include border-radius(0.25rem);
}
.radius-md {
  @include border-radius(0.375rem);
}
.radius-lg {
  @include border-radius(0.5rem);
}
.radius-xl {
  @include border-radius(0.625rem);
}
.radius-xxl {
  @include border-radius(0.75rem);
}

/* ============= Typographt ==============*/
.heading-one {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #29363d;
}
.heading-two {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29363d;
}
.heading-three {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #29363d;
}
.running-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5c6970;
}
.small-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8f9ca3;
}
.link-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #4799eb;
}
/* ============= Typographt END ==============*/
