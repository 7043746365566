/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: font-url("public/fonts/Inter-Thin.woff2") format("woff2"), font-url("public/fonts/Inter-Thin.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: font-url("public/fonts/Inter-ExtraLight.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: font-url("public/fonts/Inter-Light.woff2") format("woff2"), font-url("public/fonts/Inter-Light.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: font-url("public/fonts/Inter-Regular.woff2") format("woff2"), font-url("public/fonts/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: font-url("public/fonts/Inter-Medium.woff2") format("woff2"), font-url("public/fonts/Inter-Medium.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: font-url("public/fonts/Inter-SemiBold.woff2") format("woff2"), font-url("public/fonts/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: font-url("public/fonts/Inter-Bold.woff2") format("woff2"), font-url("public/fonts/Inter-Bold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: font-url("public/fonts/Inter-ExtraBold.woff2") format("woff2"), font-url("public/fonts/Inter-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  src: font-url("public/fonts/Inter-Black.woff2") format("woff2"), font-url("public/fonts/Inter-Black.woff") format("woff"); }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
Animation
=======*/
.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-column, .inner-header, .inner-header .top-menu li a .profile-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flexbox, .inner-header .header-block, .inner-header .header-block-menu, .inner-header .brand-icon a, .inner-header .page-details, .inner-header .top-menu, .inner-header .top-menu li a, .inner-header .folded-menu,
.inner-header .unfolded-menu, .profile-img {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.all-center, .inner-header .brand-icon a, .profile-img {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .inner-header .header-block, .inner-header .header-block-menu, .inner-header .page-details, .inner-header .top-menu, .inner-header .top-menu li a, .inner-header .folded-menu,
.inner-header .unfolded-menu {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.btn-gradient {
  background-image: -webkit-linear-gradient(legacy-direction(to right), #35807e, #35807e);
  background-image: -webkit-linear-gradient(to right, #35807e, #35807e);
  background: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: -ms-linear-gradient(to right, #35807e, #35807e);
  background-image: linear-gradient(to right, #35807e, #35807e); }

.radius-xs {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem; }

.radius-sm {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem; }

.radius-md {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem; }

.radius-lg {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem; }

.radius-xl {
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem; }

.radius-xxl {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem; }

/* ============= Typographt ==============*/
.heading-one {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #29363d; }

.heading-two {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29363d; }

.heading-three {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #29363d; }

.running-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5c6970; }

.small-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8f9ca3; }

.link-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #4799eb; }

/* ============= Typographt END ==============*/
.inner-header {
  flex: 0 1 auto;
  background-color: #fafafa;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  @media (max-width: 320px) {
    .inner-header {
      padding: 0.75rem 0; } }

@media (min-width: 320px) and (max-width: 991px) {
  .inner-header {
    padding: 0.75rem 0; } }
  .inner-header.survey-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0; }
  .inner-header .header-block {
    height: 63px; }
    @media (max-width: 868px) {
      .inner-header .header-block {
        margin: auto !important;
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto; } }
  @media (max-width: 868px) {
    .inner-header .header-block-menu {
      width: 100%;
      margin-bottom: 0.5rem; } }
  .inner-header .brand-icon {
    flex: 0 0 auto;
    font-size: 16px;
    color: white; }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .brand-icon {
    flex: 1 1 auto; } }
    @media (max-width: 320px) {
      .inner-header .brand-icon {
        flex: 1 1 50%; } }
    .inner-header .brand-icon a {
      min-width: 125px;
      max-width: 125px; }
      @media (max-width: 319px) {
        .inner-header .brand-icon a {
          max-width: 100%;
          min-width: 100%; } }
      .inner-header .brand-icon a img {
        width: 100%; }
  .inner-header .page-details {
    margin: 0; }
    .inner-header .page-details li {
      margin-left: 35px; }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .page-details li {
    margin-left: 12px; } }
      @media (max-width: 320px) {
        .inner-header .page-details li {
          margin-left: 12px; } }
      .inner-header .page-details li a {
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out; }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .page-details li a {
    font-size: 14px; } }
        @media (max-width: 320px) {
          .inner-header .page-details li a {
            font-size: 14px; } }
        .inner-header .page-details li a:hover, .inner-header .page-details li a:focus {
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
          color: #ff903c; }
  .inner-header .slot-block {
    flex: 0 0 auto; }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .slot-block {
    flex: 1 1 auto; } }
  .inner-header .top-menu {
    margin-left: auto; }
    @media (max-width: 320px) {
      .inner-header .top-menu {
        margin-top: .5rem; } }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .top-menu {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    margin-top: .5rem;
    margin-bottom: .5rem; } }

@media (min-width: 468px) and (max-width: 991px) {
  .inner-header .top-menu {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    margin-top: .5rem;
    margin-bottom: .5rem; } }

@media (min-width: 992px) and (max-width: 767px) {
  .inner-header .top-menu {
    margin: 0; } }
    .inner-header .top-menu .menu-icon {
      width: 15px;
      fill: rgba(0, 0, 0, 0.5); }
    .inner-header .top-menu li {
      flex: 0 0 auto; }
      .inner-header .top-menu li:not(:last-child) {
        margin-right: 15px; }
      .inner-header .top-menu li a .profile-img {
        flex: 0 0 auto;
        background-color: #eaf7f5; }
        .inner-header .top-menu li a .profile-img svg {
          width: 78%; }
          .inner-header .top-menu li a .profile-img svg path {
            fill: #44817e; }
      .inner-header .top-menu li a .profile-info {
        padding-left: 1rem; }
        @media (max-width: 320px) {
          .inner-header .top-menu li a .profile-info {
            padding-left: .2rem; } }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .top-menu li a .profile-info {
    padding-left: .2rem; } }
        .inner-header .top-menu li a .profile-info h3 {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 0px;
          color: rgba(0, 0, 0, 0.8); }
          @media (max-width: 320px) {
            .inner-header .top-menu li a .profile-info h3 {
              font-size: 10px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .top-menu li a .profile-info h3 {
    font-size: 10px; } }
        .inner-header .top-menu li a .profile-info span {
          text-transform: lowercase;
          font-size: 13px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6); }
          @media (max-width: 320px) {
            .inner-header .top-menu li a .profile-info span {
              font-size: 11px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .top-menu li a .profile-info span {
    font-size: 11px; } }
  .inner-header .top-menu-cursor {
    cursor: default; }
  .inner-header .folded-menu li,
  .inner-header .unfolded-menu li {
    flex: 0 auto; }
    .inner-header .folded-menu li:last-child,
    .inner-header .unfolded-menu li:last-child {
      margin-right: 20px; }
    .inner-header .folded-menu li a,
    .inner-header .unfolded-menu li a {
      display: block;
      padding: 15px 10px;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      color: #7d8b97;
      font-weight: 600;
      text-decoration: none; }
      .inner-header .folded-menu li a:hover, .inner-header .folded-menu li a.active,
      .inner-header .unfolded-menu li a:hover,
      .inner-header .unfolded-menu li a.active {
        margin: 0;
        color: #ff903c; }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .unfolded-menu {
    display: none; } }
  .inner-header .folded-menu {
    margin-right: 5px; }
    .inner-header .folded-menu i {
      font-size: 24px;
      font-weight: bold; }

@media (min-width: 320px) and (max-width: 767px) {
  .inner-header .folded-menu i {
    font-size: 20px; } }
    @media (min-width: 768px) {
      .inner-header .folded-menu {
        display: none; } }

.profile-img {
  width: 38px;
  height: 38px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: #eaf7f5; }
  @media (max-width: 320px) {
    .profile-img {
      width: 21px;
      height: 21px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .profile-img {
    width: 21px;
    height: 21px; } }
  .profile-img svg {
    width: 78%; }
    .profile-img svg path,
    .profile-img svg circle {
      fill: #44817e; }

@media (min-width: 320px) and (max-width: 767px) {
  .profile-img {
    width: 21px;
    height: 21px; } }
  .profile-img img {
    width: 100%; }
  .profile-img:after {
    border: 0px;
    width: 0px;
    height: 0px;
    margin: 0px; }

.page-graphics {
  z-index: -1;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-animation: fadeIn 0.8s;
  -moz-animation: fadeIn 0.8s;
  -o-animation: fadeIn 0.8s;
  animation: fadeIn 0.8s; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  @media (max-width: 319px) {
    .page-graphics {
      display: none; } }

@media (min-width: 320px) and (max-width: 767px) {
  .page-graphics {
    display: none; } }

@media (min-width: 768px) and (max-width: 992px) {
  .page-graphics {
    display: none; } }

@media (min-width: 992px) and (max-width: 1024px) {
  .page-graphics {
    display: none; } }
  .page-graphics .container {
    position: relative;
    z-index: -1;
    background-color: transparent;
    height: 100%; }
  .page-graphics .graphics-left,
  .page-graphics .graphics-right {
    display: block;
    width: 100%;
    position: absolute;
    transform: scale(0.8);
    z-index: 9; }
  .page-graphics .graphics-left {
    right: calc(100% - 110px);
    top: 350px; }
  .page-graphics .graphics-right {
    left: calc(100% - 110px);
    top: -10px; }

.selected_color {
  color: #ff903c !important; }
